import useCustomAndGenericCategoriesByCompany from './useCustomAndGenericCategoriesByCompany';
import useCustomerId from './useCustomerId';

/** Obtains a list of categories by company */
const useSelectedCompanyCustomAndGenericCategories = (allowRequest = true) => {
  const { customerId } = useCustomerId();

  const props = useCustomAndGenericCategoriesByCompany({
    allowRequest,
    customerId,
  });

  return props;
};

export default useSelectedCompanyCustomAndGenericCategories;
