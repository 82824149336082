import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Typography } from '@mui/material';
import { IconArrowNarrowLeft } from '@tabler/icons-react';
import { Button } from '@atoms';
import { OPERATIONS, OPERATIONS_SUPERVISOR } from '@globalConstants';
import {
  useURLparams,
  useConsumptionList,
  useDownloadReceipt,
  useErrorSnackbar,
  useRoles,
  useCustomerId,
} from '@hooks';
import { MainMenuLayout, FullWidthContent, BoxContainer } from '@layouts';
import { Table } from '@molecules';
import { SelectCustomer } from '@organisms';
import { DEFAULT_PAGE, DEFAULT_ROWS_PER_PAGE, buildColumnsForConsumptionList } from './constants';
import FilterCard from './FilterCard';
import { ConsumptionParams, PaginatedConsumption } from './types';

/**
 * ConsumptionListScreen
 */
const ConsumptionList = () => {
  const { t } = useTranslation();
  const { params, setURLparams } = useURLparams();
  const { customerId } = useCustomerId();
  const { data, isLoading, handlePageChange, handleRowsPerPageChange, onFiltersFormSubmit } =
    useConsumptionList(customerId, {
      params,
      setURLparams,
      onError: handleErrors,
    });
  const { downloadReceipt } = useDownloadReceipt();
  const { ErrorSnackbar, showErrorSnackbar } = useErrorSnackbar();
  const { validateRoles } = useRoles();
  const isOperations = validateRoles([OPERATIONS, OPERATIONS_SUPERVISOR]);

  /**
   * Handler for the params change
   * @param params
   * @returns void
   */
  const handlerParamsChange = async (params: Record<string, string>) => {
    onFiltersFormSubmit({
      ...params,
      ...(params.toDate && { toDate: format(new Date(params.toDate), 'yyyy-MM-dd') }),
      ...(params.fromDate && { fromDate: format(new Date(params.fromDate), 'yyyy-MM-dd') }),
    });
  };

  /**
   * Handle the errors of the request
   */
  function handleErrors(error: { response: { status: number } }) {
    switch (error?.response?.status) {
      case 400:
        showErrorSnackbar(t('EMPLOYEES:ERRORS:INVALID_SEARCH'));
        break;
      default:
        if (error) {
          showErrorSnackbar(t('EMPLOYEES:ERRORS:GENERIC_ERROR'));
        }
    }
  }

  /**
   * Handle the back button
   * @returns void
   */
  const handleBack = () => {
    setURLparams({});
  };

  //TODO: Cast the typing to avoid build errors.
  const dataLength = (data as PaginatedConsumption)?.page?.length || 0;

  return (
    <MainMenuLayout>
      <FullWidthContent>
        {!customerId ? (
          <BoxContainer>
            <Typography variant='h3'>{t('COMMON:COMPANY')}</Typography>
            <SelectCustomer />
          </BoxContainer>
        ) : (
          <>
            <FilterCard params={params} onSubmit={handlerParamsChange} dataLength={dataLength} />
            <Table
              //TODO: Cast the typing to avoid build errors.
              count={(data as PaginatedConsumption)?.totalCount || 0}
              isLoading={isLoading}
              items={(data as PaginatedConsumption)?.page || []}
              columns={buildColumnsForConsumptionList(downloadReceipt)}
              onPageChange={handlePageChange as () => void}
              onRowsPerPageChange={handleRowsPerPageChange as () => void}
              //TODO: Cast the typing to avoid build errors.
              page={(params as ConsumptionParams)?.page || DEFAULT_PAGE}
              rowsPerPage={(params as ConsumptionParams)?.limit || DEFAULT_ROWS_PER_PAGE}
            />
            {isOperations && (
              <Button
                onClick={handleBack}
                variant='outlined'
                startIcon={<IconArrowNarrowLeft />}
                text={t('COMMON:GO_BACK')}
              />
            )}
          </>
        )}
        <ErrorSnackbar />
      </FullWidthContent>
    </MainMenuLayout>
  );
};

export default ConsumptionList;
