import useCustomerId from './useCustomerId';
import useProductsByCustomer from './useProductsByCustomer';

/**
 * Obtains a list of products by customer
 */
const useSelectedCustomerProducts = (allowRequest = true) => {
  const { customerId } = useCustomerId();
  const props = useProductsByCustomer({ allowRequest, customerId });

  return props;
};

export default useSelectedCustomerProducts;
