import { defaultProductColor } from 'theme/colors';
import { Box, Tooltip, Typography } from '@mui/material';
import { IconCreditCard } from '@tabler/icons-react';
import { CLIENT, OPERATIONS, OPERATIONS_SUPERVISOR, PRODUCTS_COLORS } from '@globalConstants';
import { Chip } from '@molecules';
import { parseAmount } from '@utils';
import { Yup } from '@validations';
import usePartnerCustomerMessage from './data/usePartnerCustomerMessage';
import styles from './styles';

export const STATES_COLOR = {
  REGISTERED: 'success', //verde
  PROSPECT: 'primary', //azul
  ONBOARDING: 'warning', //amarillo
  DELETED: 'error', //rojo
  ACTIVATED: 'success', //verde
  LOST: 'error', //rojo
  BLOCKED: 'default', //negro
  REQUESTED: 'primary', //azul
  AVAILABLE: 'warning', //amarillo
  PRINTED: 'success', //verde
  PRINTING: 'primary', //azul
  UNKNOWN: 'default', //gris
  undefined: 'default',
};

/**
 * Employees table columns
 * @param {function} t i18n translate
 */
export const COLUMNS = (t) => [
  {
    title: 'COMMON:FIRST_NAME',
    content: (client) => {
      const isMaxCharacter = client.firstName.length > 25;
      return (
        <Tooltip title={isMaxCharacter && client.firstName} arrow>
          <Box sx={[styles.rowEllipsis, styles.nameMaxWidth]}>{client.firstName}</Box>
        </Tooltip>
      );
    },
  },
  {
    title: 'COMMON:LAST_NAME',
    content: (client) => {
      const isMaxCharacter = client.lastName.length > 25;
      return (
        <Tooltip title={isMaxCharacter && client.lastName} arrow>
          <Box sx={[styles.rowEllipsis, styles.nameMaxWidth]}>{client.lastName}</Box>
        </Tooltip>
      );
    },
  },
  {
    title: 'COMMON:EMAIL',
    content: (client) => {
      const isMaxCharacter = client.email.length > 25;
      return (
        <Tooltip title={isMaxCharacter && client.email} arrow>
          <Box sx={[styles.rowEllipsis, styles.emailMaxWidth]}>{client.email}</Box>
        </Tooltip>
      );
    },
  },
  {
    title: 'COMMON:CUIL',
    content: (client) => {
      const isMaxCharacter = client.cuit.length > 11;
      return (
        <Tooltip title={isMaxCharacter && client.cuit} arrow>
          <Box sx={[styles.rowEllipsis, styles.cuitMaxWidth]}>{client.cuit}</Box>
        </Tooltip>
      );
    },
  },
  {
    title: 'COMMON:CARD',
    content: (client) => {
      return client.status ? (
        <Chip
          label={t(`EMPLOYEES:STATUS:${client.status}`)}
          tooltip={t(`EMPLOYEES:STATUS_TOOLTIP:${client.status}`)}
          color={STATES_COLOR[client.status]}
          variant='outlined'
          sx={styles.chip}
        />
      ) : (
        <></>
      );
    },
  },
  {
    title: 'COMMON:PRODUCT',
    content: (client) => {
      const productName = client.products;

      return (
        <>
          {[productName].map((item, index) => {
            return (
              <Box key={index} sx={[styles.rowEllipsis, styles.productMaxWidth]}>
                <Tooltip title={item} arrow>
                  <IconCreditCard
                    stroke={2}
                    color={
                      client?.productColors?.main ||
                      PRODUCTS_COLORS[item]?.main ||
                      defaultProductColor.main
                    }
                  />
                </Tooltip>
                {client.balance === undefined ? (
                  <Tooltip title={t('COMMON:HIDE_AMOUNT_TOOLTIP')} arrow>
                    <Typography variant='span'>{t('COMMON:HIDE_AMOUNT')}</Typography>
                  </Tooltip>
                ) : (
                  parseAmount(client.balance)
                )}
              </Box>
            );
          })}
        </>
      );
    },
  },
  {
    title: 'COMMON:CUSTOMER_NAME',
    content: (client) => {
      const isMaxCharacter = client.legalName?.length > 25;
      return (
        <Tooltip title={isMaxCharacter && client.legalName} arrow>
          <Box sx={[styles.rowEllipsis, styles.nameMaxWidth]}>{client.legalName}</Box>
        </Tooltip>
      );
    },
    internalFeature: true,
  },
];
export const createProspectRoles = [CLIENT, OPERATIONS, OPERATIONS_SUPERVISOR];
export const DEFAULT_ROWS_PER_PAGE = 25;

/** Amount modal configuration */
export const CONFIG_AMOUNT_FIELD = (showMessage = false) =>
  [
    {
      name: 'amount',
      label: 'DISPERSIONS:FAST_DISPERSION:AMOUNT',
      defaultValue: '',
      type: 'number',
    },
    showMessage && {
      name: 'message',
      label: 'Mensaje de la notificación',
      defaultValue: 'DISPERSIONS:FAST_DISPERSION:NOTIFICATION_MESSAGE',
      type: 'select',
      getOptions: usePartnerCustomerMessage,
      parseOption: ({ templateName, movementDetail }) => ({
        text: movementDetail,
        value: templateName,
      }),
    },
  ].filter(Boolean);

export const VALIDATION_AMOUNT_SCHEMA = Yup.object().shape({
  amount: Yup.string()
    .required('COMMON:VALIDATIONS:REQUIRED_FIELD')
    .transform((value) => value.replace(',', '.'))
    .matches(/^\d+(\.\d+)?$/, 'COMMON:VALIDATIONS:INVALID_AMOUNT')
    .min(1, 'COMMON:VALIDATIONS:INVALID_AMOUNT')
    .test('is-number', 'COMMON:VALIDATIONS:INVALID_AMOUNT', (value) => !isNaN(parseFloat(value)))
    .test('checkDecimals', 'COMMON:VALIDATIONS:INVALID_AMOUNT_DECIMALS', (num) => {
      const decimals = String(num).split('.')[1];
      if (!decimals) {
        return true;
      } else {
        return decimals.length < 3;
      }
    }),
});
