import useCategoriesByCustomer from './useCategoriesByCustomer';
import useCustomerId from './useCustomerId';

/**
 * Obtains a list of categories by company
 */
const useSelectedCustomerCategories = (allowRequest = true) => {
  const { customerId } = useCustomerId();
  const props = useCategoriesByCustomer({ allowRequest, customerId });
  return props;
};

export default useSelectedCustomerCategories;
