import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  List,
  ListItemButton,
  Autocomplete as MaterialAutocomplete,
  TextField,
  useTheme,
} from '@mui/material';
import { IconCircleX, IconChevronDown } from '@tabler/icons-react';
import styles from './styles';

/**
 * @component Autocomplete Component
 * This component renders an Autocomplete select component using Material-UI.
 * @returns {JSX.Element} - The Autocomplete component.
 */
const Autocomplete = ({
  disabled = false,
  isLoading,
  value,
  onChange,
  parseOption,
  items,
  placeholder = 'COMMON:SELECT_PLACEHOLDER',
  noOptionsText = 'COMMON:OPTION_NOT_FOUND',
  optionsErrorText = 'COMMON:OPTIONS_ERROR',
  getOptionLabel,
  isOptionEqualToValue,
  onClear,
}) => {
  const [inputValue, setInputValue] = useState('');

  const { t } = useTranslation();
  const { palette } = useTheme();

  const selectOptions = items && (parseOption ? items.map((item) => parseOption(item)) : items);

  return (
    <MaterialAutocomplete
      clearIcon={<IconCircleX color={palette.primary.main} onClick={onClear} />}
      popupIcon={<IconChevronDown />}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      disabled={disabled}
      noOptionsText={
        isLoading ? t('COMMON:LOADING_OPTIONS') : items ? t(noOptionsText) : t(optionsErrorText)
      }
      getOptionLabel={getOptionLabel}
      options={selectOptions || []}
      placeholder={t(placeholder)}
      value={value}
      onChange={onChange}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={(params) => (
        <TextField variant='outlined' placeholder={t(placeholder)} {...params} />
      )}
      size='medium'
      renderOption={(props, option) => (
        <ListItemButton {...props} sx={styles.optionButton}>
          {option.label}
        </ListItemButton>
      )}
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      ListboxComponent={({ className, ...props }) => {
        return <List {...props} sx={styles.list} />;
      }}
    />
  );
};

Autocomplete.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  parseOption: PropTypes.func,
  items: PropTypes.array,
  placeholder: PropTypes.string,
  noOptionsText: PropTypes.string,
  optionsErrorText: PropTypes.string,
  getOptionLabel: PropTypes.func,
  isOptionEqualToValue: PropTypes.func,
  onClear: PropTypes.func,
};

export default Autocomplete;
