import { useState } from 'react';
import { isAxiosError } from 'axios';
import { apiClient } from 'config/api';

export interface FastDispersionFilters {
  cuil?: string;
  product?: string;
  categories?: string[];
  firstName?: string;
  lastName?: string;
}

interface CreateFastDispersionResponse {
  dispersionId: string;
  total: number;
  count: number;
}

interface CommonError {
  code: string;
  message: string;
}

interface CommonServiceError {
  errors: CommonError[];
  code: string;
  origin: string;
}

interface ErrorMessage {
  title: string;
  description: string;
}
interface Employees {
  cuil?: string;
  cuit?: string;
  product?: string;
  products?: string;
  categories?: string[];
  firstName?: string;
  lastName?: string;
}

const DEFAULT_ERROR_MESSAGE: ErrorMessage = {
  title: 'COMMON:ERRORS:GENERIC_ERROR_TITLE',
  description: 'COMMON:ERRORS:DEFAULT_ERROR',
};

/** Handle create dispersion common errors
 *
 * @returns error message
 */
function handleCreateDispersionError(error: unknown): ErrorMessage {
  if (!isAxiosError<CommonServiceError>(error)) {
    return DEFAULT_ERROR_MESSAGE;
  }
  const errorMessage = error.response?.data?.errors[0]?.message;

  switch (errorMessage) {
    case 'Account balance is less than dispersion amount.': {
      return {
        title: 'DISPERSIONS:ERRORS:INSUFFICIENT_BALANCE_TITLE',
        description: 'DISPERSIONS:ERRORS:INSUFFICIENT_BALANCE_DESCRIPTION',
      };
    }
    default: {
      return DEFAULT_ERROR_MESSAGE;
    }
  }
}

/**
 * Custom hook to manage prospect creation and confirmation.
 * @returns {object} Object containing data, loading state, errors, and functions for creating and confirming prospects.
 */
function useFastDispersion() {
  const [isLoading, setIsloading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(DEFAULT_ERROR_MESSAGE);

  /**
   * Function to create a fast dispersion.
   * @param {prospects} body - The list prospects for fast dispersion.
   * @returns {Promise<object>} A Promise that resolves with the response data once the mutation is complete.
   */
  const fastDispersion = async ({
    employees = [],
    amount,
    customerId,
    message,
  }: {
    employees: Employees[];
    amount: string;
    customerId?: number;
    message: string;
  }) => {
    setIsloading(true);
    setIsError(false);

    try {
      const file = employees.map(({ cuil, cuit, product, products, categories }) => ({
        CUIL: cuil || cuit,
        Monto: Number(amount.replace(',', '.')),
        Producto: product || products,
        Rubro: categories,
      }));
      const { data } = await apiClient.post<CreateFastDispersionResponse>('/v2/dispersion/json', {
        file,
        ...(customerId && { customerId }),
        message,
      });
      setIsloading(false);
      return data;
    } catch (error) {
      const errorMessage = handleCreateDispersionError(error);

      setErrorMessage(errorMessage);
      setIsError(true);
      setIsloading(false);
    }
  };

  /** Create a fast dispersion with all filtered data selected */
  const createFastDispersionWithAllFilteredData = async ({
    amount,
    filters,
    customerId,
    allowDispersion = false,
    message,
  }: {
    customerId: number;
    amount: string;
    filters: FastDispersionFilters;
    allowDispersion?: boolean;
    message: string;
  }) => {
    setIsloading(true);
    setIsError(false);

    try {
      const response = await apiClient.post<CreateFastDispersionResponse>('/v2/dispersion/total', {
        ...(customerId && { customerId }),
        amount: Number(amount.replace(',', '.')),
        ...filters,
        allowDispersion,
        message,
      });
      setIsloading(false);
      return response.data;
    } catch (error) {
      const errorMessage = handleCreateDispersionError(error);

      setErrorMessage(errorMessage);
      setIsError(true);
      setIsloading(false);
    }
  };

  /**
   * Function to confirm the creation of a prospect.
   * @param {object} body - The request body for confirming the fast dispersion.
   * @returns {Promise<object>} A Promise that resolves with the response data once the mutation is complete.
   */
  const confirmFastDispersion = async (body: object) => {
    setIsloading(true);
    setIsError(false);
    setErrorMessage({
      title: 'COMMON:ERRORS:GENERIC_ERROR_TITLE',
      description: 'COMMON:ERRORS:DEFAULT_ERROR',
    });
    try {
      const { data } = await apiClient.put('/s3/dispersion', body);
      setIsloading(false);
      return data;
    } catch (error) {
      if (isAxiosError(error) && error?.response?.data?.errors?.[0]?.message === 'Key not found') {
        setErrorMessage({
          title: 'DISPERSIONS:ERRORS:EXPIRED_TITLE',
          description: 'DISPERSIONS:ERRORS:EXPIRED_DESCRIPTION',
        });
      } else {
        setErrorMessage({
          title: 'DISPERSIONS:ERRORS:CONFIRMATION_ERROR_TITLE',
          description: 'DISPERSIONS:ERRORS:CONFIRMATION_ERROR_DESCRIPTION',
        });
      }
      setIsError(true);
      setIsloading(false);
    }
  };

  return {
    isLoading,
    isError,
    setIsError,
    errorMessage,
    fastDispersion,
    confirmFastDispersion,
    createFastDispersionWithAllFilteredData,
  };
}

export default useFastDispersion;
