import useSWR from 'swr';

/** Handle state of fetching postal address by company id */
export default function usePostalAddresByCustomerId(customerId: number | undefined) {
  const props = useSWR(customerId ? [`/partner/address?customerId=${customerId}`] : null, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });

  return props;
}
