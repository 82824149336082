import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useSelectedCompanyDetails, useUser } from '@hooks';
import { BoxContainer } from '@layouts';
import { CUSTOMER_TYPES } from './constants';
import SelectCustomer from '../SelectCustomer';

interface SelectCustomerCardProps {
  disabled?: boolean;
  disableHeader?: boolean;
  showCustomerType?: boolean;
}

/**
 * @component SelectedCompany
 * @description This component renders a card with a select customer component.
 */
const SelectCustomerCard: React.FC<SelectCustomerCardProps> = ({
  disabled = false,
  disableHeader = false,
  showCustomerType = false,
}: SelectCustomerCardProps) => {
  const { t } = useTranslation();
  const { data } = useSelectedCompanyDetails();
  const { user } = useUser();

  if (user?.isInternal) {
    return (
      <BoxContainer>
        <Typography variant='h4'>{t('COMMON:COMPANY')}</Typography>
        <SelectCustomer disabled={disabled} />
        {data?.customerType && showCustomerType && (
          <Typography variant='h5'>
            {t('COMMON:CUSTOMER_TYPE_LABEL', {
              customerType: CUSTOMER_TYPES[data.customerType as keyof typeof CUSTOMER_TYPES],
            })}
          </Typography>
        )}
      </BoxContainer>
    );
  } else {
    return disableHeader ? null : (
      <BoxContainer>
        <Typography variant='h4'>
          {data?.description
            ? t('DISPERSIONS:HEADING', { name: data.description })
            : t('COMMON:HELLO')}
        </Typography>
      </BoxContainer>
    );
  }
};

export default SelectCustomerCard;
