import useSWR from 'swr';
import { useCustomerId } from '@hooks';

/**
 * SWR hook to fetch consumption by category
 */
const useConsumptionByCategory = (params: Record<string, string>) => {
  const { customerId } = useCustomerId();
  const query = new URLSearchParams({ ...params, customerId: String(customerId) }).toString();
  const { data, isLoading, error, mutate } = useSWR(
    () => (params?.product ? `/v2/client/consumption-by-category?${query}` : false),
    {
      revalidateOnFocus: false,
    }
  );

  return { data, isLoading, error, mutate };
};

export default useConsumptionByCategory;
