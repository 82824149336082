import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { Button, FieldList, Input } from '@atoms';
import { useFilterInternalFeatures } from '@hooks';
import { Dialog, SelectFetch } from '@molecules';
import { clearObject } from '@utils';
import styles from './styles';

/**
 * Renders a modal with the inputs fields and search clients
 * @param {object} props
 * @param  {boolean} props.isOpen is modal is or not visible
 * @param  {() => void | () => Promise<void>} props.onClose function to execuete after modal close
 * @param  {boolean} props.isLoading flag indicating whether the request is loading
 * @param  {object} props.params the current URL params
 * @param  {() => void | () => Promise<void>} props.onFiltersFormSubmit function to set clients data
 */
const FormModal = ({
  isOpen,
  onClose,
  isLoading,
  onSubmit,
  configFields,
  validationSchema,
  title,
  description = '',
  initialValues,
  showClearFilter = true,
  textButtonCancel = 'COMMON:CANCEL',
  textButtonConfirm = 'COMMON:CONFIRM',
}) => {
  const { t } = useTranslation();
  const fields = useFilterInternalFeatures(configFields);

  const {
    handleChange: formikHandleChange,
    handleSubmit,
    handleBlur,
    isValid,
    errors,
    values,
    setValues,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      onSubmit(values);
      resetForm();
    },
  });

  /**
   * Clears the filters
   */
  function handleClearFilters() {
    const newValues = clearObject(values);
    setValues(newValues);
  }

  /**
   * Handles the form change
   * Trims whitespaces at the beginning and end if the event is a clipboard paste
   */
  function handleChange(name, type) {
    const handler = formikHandleChange(name);
    /** Sanitizes the input value based on the specified type. */
    const sanitizedValue = (value) => {
      return type === 'number'
        ? value
            .replace(/\./g, ',')
            .replace(/[^0-9,]/g, '')
            .split(',')
            .slice(0, 2)
            .join(',')
        : value;
    };
    return (event) => {
      if (event.type === 'paste') {
        event.preventDefault();
        handler(sanitizedValue(event.clipboardData.getData('text/plain').trim()));
      } else {
        handler(sanitizedValue(event.target.value));
      }
    };
  }

  const disableButton = isLoading || !isValid;

  /**
   * Handles the modal close
   */
  const hadlerClose = () => {
    onClose();
    resetForm();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={hadlerClose}
      dialogTitle={
        <Box sx={styles.header}>
          <Typography variant='h4'>{t(title)}</Typography>
          {showClearFilter && (
            <Button
              customStyle={{ button: { color: 'primary.main' } }}
              text={t('COMMON:CLEAR_FILTER')}
              color='primary'
              variant='link'
              onClick={handleClearFilters}
            />
          )}
        </Box>
      }
      dialogContent={
        <>
          {description && <Typography variant='p'>{t(description)}</Typography>}
          <FieldList>
            {fields.map(({ name, label, type, getOptions, parseOption }, key) => {
              return type === 'select' ? (
                <SelectFetch
                  name={name}
                  placeholder={label}
                  value={values[name]}
                  onChange={formikHandleChange}
                  onBlur={handleBlur(name)}
                  parseOption={parseOption}
                  key={key}
                  getOptions={getOptions}
                  error={errors[name]}
                  helperText={errors[name]}
                />
              ) : (
                <Input
                  key={key}
                  label={label}
                  variant='outlined'
                  size='small'
                  name={name}
                  value={values[name]}
                  onChange={handleChange(name, type)}
                  inputProps={{ onPaste: handleChange(name, type) }}
                  onBlur={handleBlur(name)}
                  helperText={errors[name]}
                  error={errors[name]}
                  fullWidth
                />
              );
            })}
          </FieldList>
        </>
      }
      dialogActions={
        <>
          <Button
            text={t(textButtonCancel)}
            color='primary'
            variant='outlined'
            onClick={hadlerClose}
          />
          <Button
            variant='contained'
            color='primary'
            type='submit'
            isLoading={isLoading}
            disabled={disableButton}
            onClick={handleSubmit}
            text={t(textButtonConfirm)}
          />
        </>
      }
    />
  );
};

export default FormModal;

FormModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  configFields: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  showClearFilter: PropTypes.bool,
  textButtonCancel: PropTypes.string,
  textButtonConfirm: PropTypes.string,
  initialValues: PropTypes.object.isRequired,
};
