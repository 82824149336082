import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Alert, Box, Grid, List, SvgIcon, useMediaQuery } from '@mui/material';
import { IconSearch, IconFileExport } from '@tabler/icons-react';
import { ListPlaceholder, Button, DateTimePicker, FieldList, Select, IconButton } from '@atoms';
import { PERMISSIONS } from '@globalConstants';
import {
  useExportAllUserMovements,
  useExportAllUserMovementsByAccountNumber,
  useFilterInternalFeatures,
  useMovements,
  usePermissions,
  useURLparams,
  useUser,
  useUserAccountNumbers,
} from '@hooks';
import { Table } from '@molecules';
import { clearObject, isoStringToDate } from '@utils';
import {
  employeeDetailsClick,
  employeeDetailsFilterMovements,
  EVENT_EMPLOYEE_DETAILS_CLEAR_FILTER_MOVEMENTS,
  EVENT_EMPLOYEE_DETAILS_EXPORT_MOVEMENTS,
} from '@modules/employeeDetails/events';
import {
  ACCOUNT_NUMBER_FILTER,
  COLUMNS,
  DATE_PICKERS,
  DATE_PICKER_PROPS,
  FILTER_FIELDS,
  validationSchema,
} from './constants';
import styles from './styles';
/**
 * Component for the account details tab
 * @param {object} props
 * @param {object} props.client
 * @param {boolean} props.showAllMovements
 * @param {string} props.accountNumber
 */
const MovementsTab = ({ client, showAllMovements, accountNumber, accountId }) => {
  const [openFilters, setOpenFilters] = useState(false);
  const { user } = useUser();
  const { params, setURLparams } = useURLparams(); //use params object to control usePagination searches
  const navigate = useNavigate();
  const { t } = useTranslation();
  const responsive = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  /**
   * Go to movement detail screen
   */
  function navigateToDetail(movement, clientId) {
    navigate(`/movimiento/${movement.id}?${new URLSearchParams({ clientId }).toString()}`);
  }

  /**
   * change date field
   */
  function handleDateChange(fieldName, date) {
    setFieldValue(fieldName, date);
  }

  /**
   * Function to prevent additional custom params being overwritten
   */
  function handleNewSearchParams(newValues) {
    if (newValues.accountNumber === t('COMMON:ALL') && showAllMovements) {
      delete newValues.accountNumber;
    }

    setURLparams({ ...newValues, ...(showAllMovements && { tab: 'movimientos' }) });
  }

  const canReadDetails = usePermissions([PERMISSIONS.viewUserMovementDetail]);
  const movementParams = params;
  if (!showAllMovements) {
    movementParams.accountNumber = accountNumber;
  }

  const { data, isLoading, error, handlePageChange, handleRowsPerPageChange, onFiltersFormSubmit } =
    useMovements(client.id, accountId, {
      params: movementParams,
      setURLparams: handleNewSearchParams,
    });

  const { data: accountNumbers } = useUserAccountNumbers(client.id);
  const now = new Date();
  const monthAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);

  const fromDate = params?.fromDate ? isoStringToDate(params?.fromDate) : monthAgo;
  const toDate = params?.toDate ? isoStringToDate(params?.toDate) : now;

  const initialValues = {
    fromDate,
    toDate,
    transactionStatus: params?.transactionStatus || '',
    transactionType: params?.transactionType || '',
    accountNumber: params?.accountNumber || t('COMMON:ALL'),
  };

  const columns = COLUMNS(user.isInternal, showAllMovements);
  const filteredInternalColumns = useFilterInternalFeatures(columns);
  const filteredColumns = filteredInternalColumns.filter((item) => !item.hideColumn);

  const { handleChange, setFieldValue, handleSubmit, isValid, errors, values, setValues } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: onFiltersFormSubmit,
    });

  const { exportAllUserMovements, isLoading: isLoadingExport } = useExportAllUserMovements();
  const { exportAllUserMovementsByAccountNumber, isLoading: isLoadingExportByAccountNumber } =
    useExportAllUserMovementsByAccountNumber();

  /**
   * Handles the export of the account movements
   * @returns {Promise<void>} Promise
   */
  const handleExport = async () => {
    employeeDetailsClick(EVENT_EMPLOYEE_DETAILS_EXPORT_MOVEMENTS);
    await handleSubmit();
    if (showAllMovements) {
      exportAllUserMovements(
        values.accountNumber === t('COMMON:ALL') ? accountNumbers.join(',') : values.accountNumber,
        {
          ...(values.fromDate && { fromDate: values.fromDate }),
          ...(values.toDate && { toDate: values.toDate }),
          ...(values.transactionType && { transactionType: values.transactionType }),
          ...(values.transactionStatus && {
            transactionStatus: values.transactionStatus,
          }),
        }
      );

      return;
    } else {
      exportAllUserMovementsByAccountNumber(params.accountNumber, accountId, {
        ...(params.fromDate && { fromDate: params.fromDate }),
        ...(params.toDate && { toDate: params.toDate }),
        ...(params.transactionType && { transactionType: params.transactionType }),
        ...(params.transactionStatus && {
          transactionStatus: params.transactionStatus,
        }),
      });
    }
  };

  /**
   * Clears the filters
   */
  function handleClearFilters() {
    employeeDetailsClick(EVENT_EMPLOYEE_DETAILS_CLEAR_FILTER_MOVEMENTS);
    const newValues = clearObject(values);
    setValues({ ...newValues, fromDate: monthAgo, toDate: now });
  }

  /**
   * open/hide more filters
   */
  const handleFiltersOnChange = () => {
    setOpenFilters(!openFilters);
  };

  const disableButton = isLoading || !isValid || isLoadingExport || isLoadingExportByAccountNumber;

  return (
    <>
      <Grid container>
        <Grid item xs={12} xl={12} sx={styles.tabGrid}>
          <Box sx={styles.container}>
            <Box sx={styles.filterDrawerStack}>
              <Box sx={styles.containerInputs}>
                <FieldList>
                  <Box sx={styles.containerRowFilter(responsive)}>
                    {DATE_PICKERS.map(({ name, label }) => (
                      <DateTimePicker
                        key={name}
                        name={name}
                        value={values[name]}
                        handleChange={(date) => handleDateChange(name, date)}
                        error={errors[name]}
                        inputLabel={label}
                        {...DATE_PICKER_PROPS}
                      />
                    ))}
                  </Box>
                  {openFilters && (
                    <>
                      <Box sx={styles.containerRowFilter(responsive)}>
                        {FILTER_FIELDS.map(
                          ({ name, label, parseOption, getItems, emptyOption }) => {
                            return (
                              <Select
                                key={name}
                                onChange={handleChange(name)}
                                value={values[name]}
                                error={errors[name]}
                                {...{
                                  name,
                                  label: t(label),
                                  items: getItems(user.isInternal),
                                  parseOption: parseOption(user.isInternal),
                                  emptyOption,
                                }}
                              />
                            );
                          }
                        )}
                      </Box>
                      {showAllMovements && (
                        <Select
                          key={ACCOUNT_NUMBER_FILTER.name}
                          onChange={handleChange(ACCOUNT_NUMBER_FILTER.name)}
                          value={values[ACCOUNT_NUMBER_FILTER.name]}
                          error={errors[ACCOUNT_NUMBER_FILTER.name]}
                          {...{
                            name: ACCOUNT_NUMBER_FILTER.name,
                            label: t(ACCOUNT_NUMBER_FILTER.label),
                            items: [t('COMMON:ALL'), ...(accountNumbers ? accountNumbers : [])],
                            parseOption: ACCOUNT_NUMBER_FILTER.parseOption(user.isInternal),
                          }}
                        />
                      )}
                    </>
                  )}
                </FieldList>
              </Box>
              <Box sx={styles.actionsContainer(responsive)}>
                <Button
                  customStyle={styles.filterButton}
                  startIcon={
                    <SvgIcon>{openFilters ? <ExpandLessIcon /> : <ExpandMoreIcon />}</SvgIcon>
                  }
                  color='primary'
                  variant='link'
                  onClick={handleFiltersOnChange}
                />
                <Button
                  text={t('COMMON:CLEAR_FILTER')}
                  onClick={handleClearFilters}
                  customStyle={styles.clearButton}
                />
                <IconButton
                  aria-label={t('COMMON:EXPORT')}
                  tooltip={t('COMMON:EXPORT')}
                  onClick={handleExport}
                  IconComponent={IconFileExport}
                  isLoading={isLoadingExport || isLoadingExportByAccountNumber}
                  disabled={disableButton || !data || data?.totalCount === 0}
                />
                <IconButton
                  aria-label={t('COMMON:SEARCH')}
                  tooltip='COMMON:SEARCH'
                  onClick={() => {
                    employeeDetailsFilterMovements(values);
                    handleSubmit();
                  }}
                  IconComponent={IconSearch}
                  isLoading={isLoading}
                  disabled={disableButton}
                  variant='contained'
                />
              </Box>
            </Box>
          </Box>
          {error ? (
            <Box p={3}>
              <Alert variant='filled' severity='error'>
                {t('MOVEMENTS:ERRORS:GENERIC_MOVEMENTS_FETCH_ERROR')}
              </Alert>
            </Box>
          ) : isLoading ? (
            <ListPlaceholder px={2} length={10} />
          ) : (
            <List disablePadding>
              <Table
                onItemClick={
                  canReadDetails ? (item) => navigateToDetail(item, client.id) : undefined
                }
                columns={filteredColumns}
                items={data?.page}
                count={data?.totalCount || 0}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={params.page || 1}
                rowsPerPage={params.limit || data?.limit || 10}
              />
            </List>
          )}
        </Grid>
      </Grid>
    </>
  );
};
MovementsTab.propTypes = {
  client: PropTypes.object,
  showAllMovements: PropTypes.bool,
  accountNumber: PropTypes.string,
  showGoBackButton: PropTypes.bool,
  accountId: PropTypes.string,
};

export default MovementsTab;
