import useSWR from 'swr';

/**
 * Custom hook for fetching client/company account data
 * @param {string} customerId - The ID of the company.
 *  @returns {Object} - The response object from the SWR fetch.
 */
const useCompanyDetails = (customerId) => {
  const props = useSWR(() => customerId && `/v2/partner/customer/${customerId}`);

  return props;
};

export default useCompanyDetails;
