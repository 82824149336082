import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material';
import { apiClient } from 'config/api';
import { Button, Snackbar } from '@atoms';
import { useSnackbar } from '@hooks';
import { employeeDetailsAllowDisperion } from '@modules/employeeDetails/events';
import styles from './styles';

interface AllowDispersionProps {
  allowDispersion: boolean;
  accountId: string;
  onSuccess: () => void;
}

/**
 * Allow dispersion component
 */
const AllowDispersion = ({ allowDispersion, accountId, onSuccess }: AllowDispersionProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { snackbarProps, showSnackbar } = useSnackbar();

  const { t } = useTranslation();

  /**
   * Handle modal confirmation
   */
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.patch(`/client/user-product/${accountId}`, {
        allow_dispersion: !allowDispersion,
      });
      employeeDetailsAllowDisperion(data);
      onSuccess();
    } catch (error) {
      showSnackbar(t('COMMON:ERRORS:DEFAULT_ERROR'), 'error');
    } finally {
      setIsLoading(false);
      setIsOpen(false);
    }
  };

  /**
   * Open modal
   */
  const handleOpen = () => {
    setIsOpen(true);
  };

  /**
   * Close modal
   */
  const handleCancel = () => {
    setIsOpen(false);
  };

  const title = allowDispersion
    ? t('EMPLOYEES:DETAIL:DISABLE_DISPERSION')
    : t('EMPLOYEES:DETAIL:ALLOW_DISPERSION');
  const body = allowDispersion
    ? t('EMPLOYEES:DETAIL:DISABLE_DISPERSION_MODAL_DESCRIPTION')
    : t('EMPLOYEES:DETAIL:ALLOW_DISPERSION_MODAL_DESCRIPTION');

  return (
    <>
      <FormControlLabel
        sx={styles.formSwitch}
        label={allowDispersion ? t('COMMON:YES') : t('COMMON:NO')}
        control={<Switch checked={allowDispersion} onChange={handleOpen} />}
      />

      {isOpen && (
        <Dialog open={isOpen} onClose={handleCancel}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <Typography variant='body1'>{body}</Typography>
            <DialogActions>
              <Button text={t('COMMON:CANCEL')} color='error' onClick={handleCancel} />
              <Button
                variant='contained'
                color='primary'
                type='submit'
                onClick={handleSubmit}
                text={t('COMMON:CONFIRM')}
                isLoading={isLoading}
                disabled={isLoading}
              />
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
      <Snackbar {...snackbarProps} />
    </>
  );
};

export default AllowDispersion;
