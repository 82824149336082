import { blue } from 'theme/colors';
import { MenuItem, Typography } from '@mui/material';
import { IconCheck } from '@tabler/icons-react';
import { usePartnerCustomers } from '@hooks';
import { useCompanyStore } from '@stores';
import styles from './styles';

/**
 * @returns Client user's companies list. User can select the one with which to operate.
 */
const ClientCompaniesList = () => {
  const { data } = usePartnerCustomers();
  const { selectedCompany, setSelectedCompany } = useCompanyStore();

  return data?.map(({ id, legalName }, key) => (
    <MenuItem key={key} onClick={() => setSelectedCompany(id)} sx={styles.companiesMenuItems}>
      <Typography variant='body'>{legalName}</Typography>
      {selectedCompany === id && <IconCheck size='1rem' color={blue[400]} />}
    </MenuItem>
  ));
};

export default ClientCompaniesList;
