import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { Stack, Grid, Typography } from '@mui/material';
import { Input, Button, Select, Autocomplete } from '@atoms';
import { CORPORATE_CLIENT } from '@globalConstants';
import {
  useCustomerId,
  useProductsByCustomer,
  useRoles,
  useSegments,
  useAreasByCustomer,
  useTranslationWithPrefix,
  useUser,
} from '@hooks';
import { BoxContainer } from '@layouts';
import { SelectFetch } from '@molecules';
import { SelectCustomer, SelectSegment } from '@organisms';
import { FIELDS_CONFIG } from './constants';
import { prospectCreationClick, EVENT_PROSPECT_CREATION_ADD_ACCOUNT } from './events';
import validationSchema from './schema';
import useProspectCreationSelectors from '../prospectUpload/useProspectCreationSelectors';

/**
 * QuickCreationModal component for adding a prospect from a Modal.
 *
 * @component
 * @param {Object} props - The properties of the component.+
 * @param {Object} initialValues -
 * @param {array} props.data - The data stored in the list.
 * @param {function} props.onClose - Callback function to handle modal closure.
 * @param {function} props.onChange - Callback function to handle modal on changed.
 * @returns {JSX.Element} QuickCreationModal component.
 */
const QuickCreationModal = ({ data, initialValues, onClose, onChange }) => {
  const { customerId } = useCustomerId();
  const { t } = useTranslationWithPrefix('PROSPECTS:QUICK_CREATION_MODAL');
  const { shouldShowSelectors, shouldShowCompanySelector } = useProspectCreationSelectors();
  const moreThanOneRow = data.length > 0;

  const { user } = useUser();
  const { data: areas } = useAreasByCustomer({
    allowRequest: user.isInternal,
    customerId: customerId,
  });

  /** Event to handle what the form does when it is submitted  */
  const onSubmit = (formValues) => {
    onChange(formValues);
    onClose();
  };

  const { validateRoles } = useRoles();
  const isClient = validateRoles([CORPORATE_CLIENT]);
  const products = useProductsByCustomer({ customerId });
  const segments = useSegments();

  const shouldShowProductsSelectorToClient = products?.data?.length > 1 && isClient;
  const shouldShowProductsSelector = user.isInternal || shouldShowProductsSelectorToClient;
  const shouldShowSegmentSelector = user.isInternal || segments?.data?.length > 1;

  const { values, errors, touched, handleSubmit, handleBlur, setFieldValue, handleChange } =
    useFormik({
      initialValues: {
        ...initialValues,
        Producto:
          products?.data?.length > 0 && !shouldShowProductsSelector ? products?.data[0] : undefined,
      },
      enableReinitialize: true,
      validationSchema: validationSchema(data),
      onSubmit,
    });

  /** Event to close the modal when the user clicks the cancel button or outside the modal. */
  const handleClose = () => {
    onClose();
  };

  /** Event to handle form fields error  */
  const handleError = (field) => Boolean(errors[field]) && touched[field];

  /** Event to handle whether or not to show the error message  */
  const handleHelperText = (field, helperText = '') =>
    handleError(field) ? t(errors[field]) : helperText;
  /**
   * Submit form button
   */
  const submitButton = () => {
    prospectCreationClick(EVENT_PROSPECT_CREATION_ADD_ACCOUNT);
    handleSubmit();
  };
  return (
    <>
      <BoxContainer>
        <Typography variant='h3'>{t('TITLE')}</Typography>
        <Grid container spacing={3}>
          {shouldShowSelectors && (
            <>
              {shouldShowCompanySelector && (
                <Grid item xs={12} md={6}>
                  <SelectCustomer placeholder={'COMMON:COMPANY'} disabled={moreThanOneRow} />
                </Grid>
              )}
              {shouldShowSegmentSelector && (
                <Grid item xs={12} md={6}>
                  <SelectSegment placeholder={'COMMON:SEGMENT'} disabled={moreThanOneRow} />
                </Grid>
              )}
            </>
          )}
          {user.isInternal && (
            <Grid item xs={12} md={6}>
              <Autocomplete
                key={String(customerId)}
                onChange={(_event, value) => setFieldValue('Area', value)}
                value={values['Area']}
                items={areas}
                placeholder={t('AREA_LABEL')}
                getOptionLabel={(value) => value.label}
                parseOption={(value) => ({ label: value.fantasyName, id: value.name })}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disabled={areas.length === 0}
                onClear={() => setFieldValue('Area', undefined)}
              />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={3}>
          {FIELDS_CONFIG(data)
            .filter((item) => item.type == 'input')
            .map(({ name, label }) => (
              <Grid key={name} item xs={12} md={6}>
                <Input
                  name={name}
                  label={t(label)}
                  value={values[name]}
                  error={handleError(name)}
                  helperText={handleHelperText(name)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
            ))}
          {shouldShowProductsSelector && (
            <Grid item xs={12} md={6}>
              <Select
                name={'Producto'}
                label={t('PRODUCT_LABEL')}
                value={values['Producto']}
                error={handleError('Producto')}
                onChange={handleChange}
                onBlur={handleBlur}
                parseOption={(value) => ({ label: value, value })}
                helperText={handleHelperText('Producto')}
                items={products?.data}
              />
            </Grid>
          )}
          {FIELDS_CONFIG(data)
            .filter((item) => item.type == 'select')
            .map(({ name, label, multiple, getOptions, parseOption, hide }) => {
              return (
                !hide && (
                  <Grid key={name} item xs={12} md={6}>
                    <SelectFetch
                      name={name}
                      label={t(label)}
                      value={values[name]}
                      error={handleError(name)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      parseOption={parseOption}
                      helperText={handleHelperText(name)}
                      getOptions={getOptions}
                      multiple={multiple}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                )
              );
            })}
        </Grid>
      </BoxContainer>
      <Stack direction='row' gap={2} justifyContent='flex-end'>
        {moreThanOneRow && (
          <Button
            variant='outlined'
            color='primary'
            type='submit'
            onClick={handleClose}
            content={t('BUTTON_CANCEL_TEXT')}
          />
        )}
        <Button
          variant='contained'
          color='primary'
          type='button'
          onClick={submitButton}
          content={t('BUTTON_SUBMIT_TEXT')}
        />
      </Stack>
    </>
  );
};

QuickCreationModal.propTypes = {
  data: PropTypes.array,
  initialValues: PropTypes.object,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
};
export default QuickCreationModal;
