import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useDispersionDetails } from '@hooks';
import { MainMenuLayout, FullWidthContent } from '@layouts';
import { Table, GoBackButton } from '@molecules';
import { trackings } from '@utils';
import { COLUMNS } from './constants';
import { MY_DISPERSIONS_GO_BACK_CLICK_EVENT } from './events';
import { styles } from './styles';
import { COLUMNS as HEADERS } from '../myDispersions/constants';

/** Dispersion Details screen */
const DispersionDetails = () => {
  const { dispersionId } = useParams();
  const { state: dispersionData } = useLocation();
  const navigate = useNavigate();
  const { isLoading, data: dispersionDetailsData } = useDispersionDetails(dispersionId);
  const mergeDispersionData = [{ ...dispersionData, credited: dispersionDetailsData.credited }];
  const detailsHeaders = [
    ...HEADERS(),
    {
      title: 'Acreditados',
      content: (dispersion) => {
        return dispersion.credited;
      },
    },
  ];
  return (
    <MainMenuLayout>
      <FullWidthContent>
        <Table columns={detailsHeaders} items={mergeDispersionData} disableControls />
        <Table
          isLoading={isLoading}
          columns={COLUMNS}
          items={dispersionDetailsData.dispersionResult}
          customStyle={styles.table}
          disableControls
        />
        <GoBackButton
          variant='outlined'
          backFunction={() => {
            trackings(MY_DISPERSIONS_GO_BACK_CLICK_EVENT);
            navigate(-1);
          }}
        />
      </FullWidthContent>
    </MainMenuLayout>
  );
};

export default DispersionDetails;
