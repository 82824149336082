import PropTypes from 'prop-types';
import { Box, SvgIcon, useMediaQuery } from '@mui/material';
import { Button, ConditionalTooltip } from '@atoms';
import {
  employeeDetailsClick,
  EVENT_EMPLOYEE_DETAILS_SELECT_PRODUCT,
} from '@modules/employeeDetails/events';
import styles from './styles';

/**
 * TabSelector
 * @component
 * @param {Object} props
 * @param {Object} props.items options of the tab selector
 * @param {Object} props.selectedTab
 * @param {String} props.keyProp the prop to use for key
 * @param {String} props.titleProp the prop to show in the title
 * @param {Object} props.customStyle
 * @param {Object} props.rightButton
 * @param {String} props.rightButton.text
 * @param {Function} props.rightButton.onClick
 * @param {Function} props.rightButton.icon
 */
const TabSelector = ({ items, selectedTab, keyProp, titleProp, customStyle = {}, rightButton }) => {
  const responsive = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const combinedButtonStyles = {
    button: {
      ...styles.button.button,
      ...customStyle.button,
    },
    text: {
      ...styles.button.text,
      ...customStyle.text,
    },
    icon: {
      ...styles.button.icon,
      ...customStyle.icon,
    },
  };

  return (
    <Box sx={styles.container(responsive)}>
      <Box sx={styles.containerResponsive(responsive)}>
        {items?.map((item, index) => {
          const text = Array.isArray(item[titleProp])
            ? item[titleProp].join(', ')
            : item[titleProp];
          const isSelected = selectedTab.account_number === item.account_number;
          return (
            <ConditionalTooltip tooltipText={text} arrow key={index}>
              <Button
                key={item[keyProp]}
                variant={isSelected ? 'outlined' : 'text'}
                startIcon={<SvgIcon sx={styles.button.icon(isSelected)}>{item.icon}</SvgIcon>}
                text={text}
                onClick={() => {
                  employeeDetailsClick(EVENT_EMPLOYEE_DETAILS_SELECT_PRODUCT);
                  item.onClick();
                }}
                customStyle={combinedButtonStyles}
              />
            </ConditionalTooltip>
          );
        })}
      </Box>
      {rightButton && (
        <Box sx={styles.rightButton}>
          <Button
            startIcon={<SvgIcon sx={styles.button.icon(false)}>{rightButton.icon}</SvgIcon>}
            text={rightButton.text}
            onClick={() => rightButton.onClick()}
            customStyle={combinedButtonStyles}
          />
        </Box>
      )}
    </Box>
  );
};

TabSelector.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.node,
    })
  ),
  selectedTab: PropTypes.object,
  setSelectedTab: PropTypes.func,
  keyProp: PropTypes.string,
  titleProp: PropTypes.string,
  customStyle: PropTypes.shape({
    text: PropTypes.object,
    button: PropTypes.object,
    icon: PropTypes.object,
  }),
  rightButton: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.elementType,
  }),
};

export default TabSelector;
