import * as Yup from 'yup';
import i18n from '@i18n';
import {
  VALIDATE_EMAIL,
  VALIDATE_OBJECT,
  VALIDATE_STRING,
  VALIDATE_STRING_OF_NUMBERS,
} from '@validations';

export const ERROR_AT_LEAST_ONE_FIELD = 'COMMON:VALIDATIONS:AT_LEAST_ONE_FIELD_REQUIRED';

const cuilValidation = VALIDATE_STRING_OF_NUMBERS.min(
  11,
  'COMMON:VALIDATIONS:CUIL_INVALID_LENGTH'
).max(11, 'COMMON:VALIDATIONS:CUIL_INVALID_LENGTH');
/**
 * Cuit form validation schema
 * @param {*} searchForm
 * @returns
 */
export const cuilValidationSchema = (searchForm) =>
  VALIDATE_OBJECT({
    cuit: cuilValidation.test(
      'item-already-in-array',
      'EMPLOYEES:MULTIPLE_SEARCH_DUPLICATED_VALUE',
      (value) => !searchForm?.cuit?.includes(value)
    ),
  });
/**
 *
 * @returns
 */
export const validationSchema = ({ user }) =>
  VALIDATE_OBJECT({
    cuit: Yup.lazy((val) =>
      Array.isArray(val)
        ? Yup.array()
            .of(cuilValidation)
            .test('2-items-or-none', 'EMPLOYEES:MULTIPLE_SEARCH_MIN', (array) => array.length !== 1)
        : cuilValidation.optional()
    ),
    dni: VALIDATE_STRING_OF_NUMBERS.optional()
      .min(7, 'COMMON:VALIDATIONS:DNI_INVALID_LENGTH')
      .max(12, 'COMMON:VALIDATIONS:DNI_INVALID_LENGTH'),
    firstName: VALIDATE_STRING,
    lastName: VALIDATE_STRING,
    email: VALIDATE_EMAIL,
    walletId: VALIDATE_STRING,
    product: Yup.string(),
    categories: Yup.array().of(Yup.string()),
  })
    .test('valid-products-search', 'EMPLOYEES:INVALID_PRODUCTS_SEARCH', function (values) {
      if (!user.isInternal || Boolean(values.customerId)) {
        return;
      }

      if (values.categories.length || values.product) {
        return this.createError({
          message: 'EMPLOYEES:INVALID_PRODUCTS_SEARCH',
          path: 'customerId',
        });
      }
    })
    .test('at-least-one-field', ERROR_AT_LEAST_ONE_FIELD, function (values) {
      const fields = Object.values(values);

      if (fields.every((field) => (Array.isArray(field) ? field.length === 0 : !field))) {
        return this.createError({
          message: ERROR_AT_LEAST_ONE_FIELD,
          path: '.',
        });
      }

      return true;
    });

export const FIELDS = [
  { name: 'cuit', label: 'COMMON:CUIL' },
  { name: 'email', label: 'COMMON:EMAIL' },
  { name: 'firstName', label: 'COMMON:FIRST_NAME' },
  { name: 'lastName', label: 'COMMON:LAST_NAME' },
  { name: 'dni', label: 'COMMON:DNI' },
];

/** Parse product options */
export const PRODUCT_PARSE_OPTIONS = (item) => ({ label: item, value: item });

/** Parse categories options */
export const CATEGORIES_PARSE_OPTIONS = (item) => ({
  label: i18n.t(`CATEGORIES:${item}`),
  value: item,
});
