import { FullWidthContent, MainMenuLayout } from '@layouts';
import { SelectCustomerCard } from '@organisms';
import DispersionsList from './DispersionsList';

/** My dispersions screen */
const MyDispersionsScreen = () => {
  return (
    <MainMenuLayout>
      <FullWidthContent>
        <SelectCustomerCard disableHeader />
        <DispersionsList />
      </FullWidthContent>
    </MainMenuLayout>
  );
};

export default MyDispersionsScreen;
