const styles = {
  main: {
    flexGrow: 1,
    py: 8,
  },
  stack: {
    gap: '9px',
    flexDirection: 'row',
  },
  rowEllipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  nameMaxWidth: {
    maxWidth: '190px',
  },
  emailMaxWidth: {
    maxWidth: '200px',
  },
  walletMaxWidth: {
    maxWidth: '190px',
  },
  productMaxWidth: {
    maxWidth: '190px',
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
  },
  cuitMaxWidth: {
    maxWidth: '100px',
  },
  statusPill: {
    maxWidth: '120px',
    padding: '5px 0',
    borderRadius: '50px',
    textAlign: 'center',
  },
  chip: {
    '& .MuiTypography-h5': {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '100%',
    },
  },
};
export default styles;
