import { EVENT_CLICK, FIREBASE, SCREENNAME_MY_DISPERSIONS } from '@globalConstants';

export const EVENT_MY_DISPERSIONS_DETAIL = 'myDispersions_detail';

export const MY_DISPERSION_DETAIL_CLICK_EVENT = [
  {
    type: FIREBASE,
    name: EVENT_CLICK,
    props: {
      screen_name: SCREENNAME_MY_DISPERSIONS,
      element: EVENT_MY_DISPERSIONS_DETAIL,
    },
  },
];
