import { DEFAULT_ROWS_PER_PAGE } from 'components/organisms/ClientMovementsTable/constants';
import { useCustomerId, usePagination } from '@hooks';
import { dateToIsoString } from '@utils';

/**
 * Data returned by the hook
 * @typedef {object} PaginationHookResult
 * @property {object} data - The response data from the API.
 * @property {boolean} isLoading - Indicates whether the data is currently being loaded. (from SWR)
 * @property {Error} error - The error object, if an error occurred during the request. (from SWR)
 * @property {boolean} isValidating - Indicates whether the data is currently being revalidated. (from SWR)
 * @property {function} handleRowsPerPageChange - Function to handle the change of rows per page.
 * @property {function} handlePageChange - Function to handle the change of page.
 * @property {boolean} filtersOpen - Indicates whether the filters are open.
 * @property {function} setFiltersOpen - Function to update the state of filtersOpen.
 * @property {function} onFiltersFormSubmit - Function to handle the submission of the filters form.
 */

/**
 *
 * A reusable SWR hook for paginated search.
 * @param {number} customerId - Options for pagination or search.
 * @param {function} paramsState - url params state.
 * @param {function} paramsState.params - Current pagination and search params (URL params).
 * @param {function} paramsState.setParams - Function to set pagination and search params.
 * @returns {PaginationHookResult} - An object containing the following properties and functions:
 */
const useCompanyMovements = ({ setURLparams, params }) => {
  const { customerId } = useCustomerId();

  /**
   * @returns Gets the URL/key. stops request if no customerId passed
   */
  const getKey = () => Boolean(customerId) && `/v2/partner/transactions/${customerId}`;

  const paramsDigets = {
    ...(params.fromDate && { fromDate: params.fromDate }),
    ...(params.toDate && { toDate: params.toDate }),
    ...(params.customerTransactionType === 'ADJUSTMENT_DEBIT' && {
      customerTransactionType: 'ADJUSTMENT',
      movementType: 'DEBIT',
    }),
    ...(params.customerTransactionType === 'ADJUSTMENT_CREDIT' && {
      customerTransactionType: 'ADJUSTMENT',
      movementType: 'CREDIT',
    }),
    ...(params.customerTransactionType &&
      !['ADJUSTMENT_DEBIT', 'ADJUSTMENT_CREDIT'].includes(params.customerTransactionType) && {
        customerTransactionType: params.customerTransactionType,
      }),
    ...(params.page && { page: params.page }),
    limit: params.limit || String(DEFAULT_ROWS_PER_PAGE),
  };

  const { onFiltersFormSubmit, ...paginationProps } = usePagination(getKey(), {
    shouldFetchWithoutParams: true,
    setURLparams,
    params: paramsDigets,
    paramKeys: ['fromDate', 'toDate', 'customerTransactionType', 'movementType', 'limit', 'page'],
  });

  /**
   * Adds custom format to the date from form
   */
  function customSubmit(values) {
    onFiltersFormSubmit({
      ...values,
      limit: params.limit || String(DEFAULT_ROWS_PER_PAGE),
      toDate: dateToIsoString(values.toDate),
      fromDate: dateToIsoString(values.fromDate),
    });
  }

  return { ...paginationProps, onFiltersFormSubmit: customSubmit };
};

export default useCompanyMovements;
