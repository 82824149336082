import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelectedCompanyCategories, useShowMenuByCategories, useURLparams } from '@hooks';
import { FullWidthContent, MainMenuLayout } from '@layouts';
import { Table } from '@molecules';
import { dateToIsoString } from '@utils';
import { COLUMNS, ITEMS_FOOTER } from './constants';
import useConsumptionByCategory from './data/useConsumptionByCategory';
import SearchBoxConsumptionByCategory from './modules/SearchBoxConsumptionByCategory';

interface SearchBoxParams {
  toDate?: Date;
  fromDate?: Date;
  product?: string;
}

/**
 * @function ConsumptionByCategory
 * @description This screen is used to display the consumption by category.
 * @returns JSX.Element
 */
const ConsumptionByCategory = () => {
  const { t } = useTranslation();
  const { params, setURLparams } = useURLparams();
  const navigate = useNavigate();
  const paramsTyped = params as Record<string, string>;
  const { isLoading: isLoadingCategories } = useSelectedCompanyCategories();
  const canViewConsuptionByCategory = useShowMenuByCategories();
  const { data, isLoading: isLoadingConsumptionByCategory } = useConsumptionByCategory(paramsTyped);
  const isLoading = isLoadingConsumptionByCategory || isLoadingCategories;

  /**
   * @function handleOnChangePage
   * @description This function is used to handle the change of page in the table.
   */
  const handleOnChangePage = (params: SearchBoxParams) => {
    const newParams: Record<string, string> = {
      ...(params?.product && { product: params.product }),
      ...(params?.fromDate && { fromDate: dateToIsoString(params.fromDate) }),
      ...(params?.toDate && { toDate: dateToIsoString(params.toDate) }),
    };
    setURLparams(newParams);
  };

  const itemsFooter = data && data?.total && data?.page?.length > 0 ? ITEMS_FOOTER(data.total) : [];

  if (!isLoadingCategories && !canViewConsuptionByCategory) {
    navigate('/home');
  } else {
    return (
      <MainMenuLayout>
        <FullWidthContent>
          <SearchBoxConsumptionByCategory
            isLoading={isLoading}
            params={paramsTyped}
            onSubmit={handleOnChangePage}
            dataLength={data?.page?.length}
          />
          <Table
            isLoading={isLoading}
            items={data?.page}
            columns={COLUMNS}
            withPagination={false}
            itemsFooter={itemsFooter}
            noResultsText={!paramsTyped?.product ? t('CONSUMPTION:NOT_SELECTED_PRODUCT') : ''}
          />
        </FullWidthContent>
      </MainMenuLayout>
    );
  }
};

export default ConsumptionByCategory;
