import useSWR from 'swr';
/**
 * Obtains a list of categories by company
 * @param allowRequest - Indicates whether the request to fetch categories should be made. If `false`, the request will not be executed.
 * @param customerId - id of the company for which categories will be fetched.
 */
const useCustomAndGenericCategoriesByCompany = ({
  allowRequest = true,
  customerId,
}: {
  allowRequest: boolean;
  customerId: number;
}) => {
  const { data, isLoading, error } = useSWR(
    () => (allowRequest && customerId ? `/v2/partner/${customerId}/categories` : false),
    {
      revalidateOnFocus: false,
    }
  );
  return { data: data || [], isLoading, error };
};
export default useCustomAndGenericCategoriesByCompany;
