import useSWR from 'swr';
import { useCustomerId } from '@hooks';

interface PartnerCustomerMessage {
  id: number;
  partnerFileName: string;
  notificationTitle: string;
  notificationBody: string;
  movementDetail: string;
  templateName: string;
  customerId: number;
}

/**
 * Obtains a list of all partner customer messages
 */
const usePartnerCustomerMessage = (allowRequest = true) => {
  const { customerId } = useCustomerId();

  const { data, isLoading, error } = useSWR<PartnerCustomerMessage[]>(
    () => (allowRequest && customerId ? `/v2/partner/customer-message/${customerId}` : false),
    {
      revalidateOnFocus: false,
    }
  );

  return { data, isLoading, error };
};

export default usePartnerCustomerMessage;
