import usePartnerCustomers from './usePartnerCustomers';

/**
 * Custom hook that provides a method to get a partner customer name on `customerId`.
 * @returns An object containing the `getNamePartnerCustomer` function.
 */
export const useNamePartnerCustomers = () => {
  const { data } = usePartnerCustomers();

  /**
   * Retrieves the name of the partner customer based on `customerId`
   * @returns {string | undefined} - The description (name) of the company if found, otherwise `undefined`.
   */
  const getNamePartnerCustomer = (customerId: string): string | undefined => {
    return data?.find(({ id }) => id === Number(customerId))?.legalName;
  };

  return { getNamePartnerCustomer };
};

export default useNamePartnerCustomers;
