import { EVENT_CLICK, EVENT_ERROR, FIREBASE, SCREENNAME_DISPERSION } from '@globalConstants';
import { trackings } from '@utils';

export const EVENT_FILE_DISPERSION_UPLOAD_FILE = 'fileDispersion_uploadFile';
export const EVENT_FILE_DISPERSION_DELETE_FILE = 'fileDispersion_deleteFile';
export const EVENT_FILE_DISPERSION_DOWNLOAD_ACCOUNTS = 'fileDispersion_downloadAccounts';
export const EVENT_FILE_DISPERSION_SCHEDULE_DATE = 'fileDispersion_scheduleDate';
export const EVENT_FILE_DISPERSION_SELECT_DATE = 'fileDispersion_selectDate';
export const EVENT_FILE_DISPERSION_CONFIRM = 'fileDispersion_confirm';

/**
 * Event object to send to trackings
 * @param element element clicked
 * @returns an array with the event
 */
export const DISPERSION_CLICK_EVENT = (element: string) => [
  {
    type: FIREBASE,
    name: EVENT_CLICK,
    props: {
      screen_name: SCREENNAME_DISPERSION,
      element: element,
    },
  },
];
/**
 * Event object to send to trackings
 * @param error
 * @returns an array with the event
 */
export const DISPERSION_ERROR_EVENT = (error: string | object) => [
  {
    type: FIREBASE,
    name: EVENT_ERROR,
    props: {
      screen_name: SCREENNAME_DISPERSION,
      error: error,
    },
  },
];
/**
 * Function to call trackings with dispersion click event
 * @param element element clicked
 */
export const dispersionClick = (element: string) => {
  trackings(DISPERSION_CLICK_EVENT(element));
};
