import { usePagination } from '@hooks';

/**
 * Data returned by the pagination hook
 * @typedef {object} PaginationHookResult
 * @property {object} data - The response data from the API.
 * @property {boolean} isLoading - Indicates whether the data is currently being loaded. (from SWR)
 * @property {Error} error - The error object, if an error occurred during the request. (from SWR)
 * @property {boolean} isValidating - Indicates whether the data is currently being revalidated. (from SWR)
 * @property {function} handleRowsPerPageChange - Function to handle the change of rows per page.
 * @property {function} handlePageChange - Function to handle the change of page.
 * @property {boolean} filtersOpen - Indicates whether the filters are open.
 * @property {function} setFiltersOpen - Function to update the state of filtersOpen.
 * @property {function} onFiltersFormSubmit - Function to handle the submission of the filters form.
 */

/**
 * A reusable SWR hook for paginated company dispersions.
 * @param {number} customerId - Options for pagination or search.
 * @param {object} paramsState - url params state.
 * @param {function} paramsState.params - Current pagination and search params (URL params).
 * @param {function} paramsState.setParams - Function to set pagination and search params.
 * @returns {PaginationHookResult} - An object containing the data and pagination properties and functions
 */
const useDispersions = (customerId, { params, setURLparams }) => {
  const key = customerId ? `/v2/dispersion/search/${customerId}` : false;

  const props = usePagination(key, { shouldFetchWithoutParams: true, params, setURLparams });

  return props;
};

export default useDispersions;
