import { Trans, useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { Alert, StrongModalText } from '@atoms';
import { parseAmount } from '@utils';

/**
 * ConfirmDisperionModal component
 * @param {Object} props - Component props
 * @param {boolean} props.openModal - Modal visibility
 * @param {function} props.handleClose - Modal close handler
 * @param {function} props.handleSubmit - Modal submit handler
 * @param {number} props.total - Total amount
 * @param {number} props.count - Number of collaborators
 * @param {string} props.companyName - selected company name
 * @param {boolean} props.submitLoading - Submit loading state
 * @param {boolean} props.disabled - Submit disabled state
 * @param {Date} props.dispersionDay - Dispersion day
 */
const DispersionConfirmationModal = ({
  openModal,
  handleConfirmation,
  total,
  count,
  companyName,
  submitLoading,
  disabled,
  dispersionDay,
  message,
}) => {
  const { t } = useTranslation();
  const formatDay = dispersionDay && format(dispersionDay, 'dd/MM/yyyy');
  const formatTime = dispersionDay && format(dispersionDay, 'HH:mm');

  let i18nKey = 'DISPERSIONS:MODAL_MESSAGE_INSTANT';
  if (dispersionDay && !message) {
    i18nKey = 'DISPERSIONS:MODAL_MESSAGE_SCHEDULED';
  } else if (!dispersionDay && message) {
    i18nKey = 'DISPERSIONS:MODAL_MESSAGE_INSTANT_WITH_MESSAGE';
  }

  return (
    <Alert
      variant='warning'
      modalVisible={openModal}
      handleClose={() => handleConfirmation(false)}
      handleSubmit={() => handleConfirmation(true)}
      closeButtonText='COMMON:CANCEL'
      title={t('DISPERSIONS:MODAL_TITLE')}
      submitLoading={submitLoading}
      disabled={disabled}
      content={
        <Typography color='gray.90'>
          <Trans
            i18nKey={i18nKey}
            components={{ bold: <StrongModalText /> }}
            values={{
              count,
              total: parseAmount(total),
              companyName,
              formatDay,
              formatTime,
              message,
            }}
          />
        </Typography>
      }
    />
  );
};

DispersionConfirmationModal.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  handleConfirmation: PropTypes.func,
  handleSubmit: PropTypes.func,
  total: PropTypes.number,
  count: PropTypes.number,
  companyName: PropTypes.string,
  submitLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  dispersionDay: PropTypes.string,
  message: PropTypes.string,
};

export default DispersionConfirmationModal;
