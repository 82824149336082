import { EVENT_CLICK, FIREBASE, SCREENNAME_MY_DISPERSIONS } from '@globalConstants';

export const EVENT_MY_DISPERSIONS_GO_BACK = 'myDispersions_goBack';

export const MY_DISPERSIONS_GO_BACK_CLICK_EVENT = [
  {
    type: FIREBASE,
    name: EVENT_CLICK,
    props: {
      screen_name: SCREENNAME_MY_DISPERSIONS,
      element: EVENT_MY_DISPERSIONS_GO_BACK,
    },
  },
];
