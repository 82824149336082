import { useState } from 'react';
import { useSelectedCompanyDetails } from '@hooks';
import { FullWidthContent, MainMenuLayout } from '@layouts';
import { SelectCustomerCard } from '@organisms';
import FileDispersion from './FileDispersion';

/** File Dispersion screen */
const FileDispersionsScreen = () => {
  const [isLoading, setLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState('');
  const { data } = useSelectedCompanyDetails();

  return (
    <MainMenuLayout>
      <FullWidthContent>
        <SelectCustomerCard disabled={isLoading} />
        <FileDispersion
          isLoading={isLoading}
          setLoading={setLoading}
          data={data}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
        />
      </FullWidthContent>
    </MainMenuLayout>
  );
};

export default FileDispersionsScreen;
