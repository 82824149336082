import useSWR from 'swr';
import useCustomerId from '../useCustomerId';
import useUser from '../useUser';

/** Obtains a list of segments */
const useSegments = (allowRequest = true) => {
  const { user } = useUser();
  const { customerId } = useCustomerId();

  const path = user.isInternal
    ? '/client/B2B-Segments'
    : `/v2/partner/customer/${customerId}/segment`;

  const { data, isLoading, error } = useSWR(() => (allowRequest ? path : false), {
    revalidateOnFocus: false,
  });

  const sortedSegments = data?.sort((a, b) => a.name.localeCompare(b.name));

  return { data: sortedSegments, isLoading, error };
};

export default useSegments;
