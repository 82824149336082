import { useState } from 'react';
import { apiClient } from '@config/api';

/**
 * Custom hook to manage prospect creation and confirmation.
 * @returns {object} Object containing data, loading state, errors, and functions for creating and confirming prospects.
 */
function useCreateProspect() {
  const [isLoading, setIsloading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Function to create a new prospect.
   * @param {object} body - The request body for creating the prospect.
   * @returns {Promise<object>} A Promise that resolves with the response data once the mutation is complete.
   */
  const createProspect = async (body) => {
    setIsloading(true);
    setError(null);
    try {
      const { data } = await apiClient.post('/v2/prospect/create', body);
      setIsloading(false);
      return data;
    } catch (error) {
      setError(error);
      setIsloading(false);
    }
  };

  /**
   * Function to confirm the creation of a prospect.
   * @param {object} body - The request body for confirming the prospect creation.
   * @returns {Promise<object>} A Promise that resolves with the response data once the mutation is complete.
   */
  const confirmCreateProspect = async (body) => {
    setIsloading(true);
    setError(null);
    try {
      const { data } = await apiClient.put('/s3/confirm-prospect-create', body);
      setIsloading(false);
      return data;
    } catch (error) {
      setError(error);
      setIsloading(false);
    }
  };

  return { isLoading, error, createProspect, confirmCreateProspect };
}

export default useCreateProspect;
