import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Alert } from '@atoms';
import {
  useCancelDispersion,
  useCompanyDetails,
  useCustomerId,
  useDispersions,
  useURLparams,
} from '@hooks';
import { Table } from '@molecules';
import { trackings } from '@utils';
import { COLUMNS, DEFAULT_PAGE_LIMIT } from './constants';
import { MY_DISPERSION_DETAIL_CLICK_EVENT } from './events';

/**
 * List of company dispersions
 */
const DispersionsList = () => {
  const { params, setURLparams } = useURLparams();
  const { customerId } = useCustomerId();
  const { data: companyData } = useCompanyDetails(customerId);
  const navigate = useNavigate();
  const { data, isLoading, handleRowsPerPageChange, handlePageChange, handleRefresh } =
    useDispersions(customerId, {
      params,
      setURLparams,
    });
  const {
    showModal,
    closeModal,
    status,
    alertMessage,
    cancelDispersion,
    isLoading: isLoadingCancelDispersion,
  } = useCancelDispersion();
  const { t } = useTranslation();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [dispersionCancelData, setDispersionCancelData] = useState({});

  /** Navigate to detail page if processed status */
  const navigateToDetail = (data) => {
    trackings(MY_DISPERSION_DETAIL_CLICK_EVENT);
    data.status === 'processed' &&
      navigate(`/dispersiones/mis-dispersiones/${data.id}`, { state: data });
  };

  /** Handle click on row item */
  const handleClick = async (id, date) => {
    setDispersionCancelData({ id, date: date?.split(',')[0], time: date?.split(',')[1] });
    setShowConfirmModal(true);
  };

  /** Function to handle confirming the cancellation of a dispersion */
  const handleConfirmCancelDispersion = async () => {
    setShowConfirmModal(false);
    await cancelDispersion(customerId, dispersionCancelData?.id);
    handleRefresh();
  };

  /** Function to handle canceling the cancellation of a dispersion */
  const handleCloseCancelDispersion = () => {
    setDispersionCancelData({});
    setShowConfirmModal(false);
  };

  return (
    data?.page && (
      <>
        <Table
          count={data.totalCount || 0}
          columns={COLUMNS(handleClick)}
          items={data.page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={params.page || 1}
          rowsPerPage={params.limit || DEFAULT_PAGE_LIMIT}
          onItemClick={navigateToDetail}
          isLoading={isLoading || isLoadingCancelDispersion}
        />
        <Alert
          variant='warning'
          modalVisible={showConfirmModal}
          handleClose={handleCloseCancelDispersion}
          handleSubmit={handleConfirmCancelDispersion}
          closeButtonText='COMMON:CANCEL'
          title={t('DISPERSIONS:CANCELLED:TITLE_MODAL_CONFIRM')}
          submitLoading={isLoading}
          content={
            <Trans
              i18nKey='DISPERSIONS:CANCELLED:DESCRIPTION_MODAL_CONFIRM'
              values={{
                name: companyData?.description,
                date: dispersionCancelData?.date,
                time: dispersionCancelData?.time,
              }}
              components={{ bold: <strong /> }}
            />
          }
        />
        <Alert
          modalVisible={showModal}
          handleClose={closeModal}
          variant={status}
          title={alertMessage.title}
          description={alertMessage.description}
        />
      </>
    )
  );
};

export default DispersionsList;
