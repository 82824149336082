import { OPERATIONS, OPERATIONS_SUPERVISOR } from '@globalConstants';
import useRoles from './useRoles';
import useSelectedCompanyDetails from './useSelectedCompanyDetails';
import useSelectedCustomerProducts from './useSelectedCustomerProducts';
import useUser from './useUser';

/**
 * @function useShowMenuByCorporateProduct
 * @description This function is used to show the menu based on the corporate product and if the selected customerId is not included in hidePersonalMovements array.
 * @return {boolean} - Returns a boolean value indicating whether the menu should be shown or not.
 * @example
 * const showMenu = useShowMenuByCorporateProduct();
 */
const useShowMenuByCorporateProduct = () => {
  const { validateRoles } = useRoles();
  const { data } = useSelectedCustomerProducts();
  const isOperationRole = validateRoles([OPERATIONS, OPERATIONS_SUPERVISOR]);
  const { user } = useUser();
  const hidePersonalMovementsArray: [number] = user?.metadata?.hidePersonalMovements ?? [];
  const selectedCompany = useSelectedCompanyDetails();
  const selectedCustomerId: number = selectedCompany?.data?.id;

  if (isOperationRole) {
    return true;
  }

  if (!data) {
    return false;
  }

  const hidePersonalMovements = !hidePersonalMovementsArray.some(
    (item) => item === selectedCustomerId
  );

  return (data.includes('Corporativo') || data.includes('Almuerzo')) && hidePersonalMovements;
};

export default useShowMenuByCorporateProduct;
