import { useCallback, useState } from 'react';
import { apiClient } from 'config/api';

interface AccountsInfoRequest {
  balance: number;
  accountNumber: number;
  cuil: number;
  id: number;
}

interface FiltersRequest {
  customerId: number;
  categories: string;
  cuit: string;
  email: string;
  firstName: string;
  lastName: string;
  product: string;
  walletId: string;
}

/** @function useRecoveryEmployeeBalance - This function is responsible for recovering the balance of an employee */
export const useRecoveryEmployeeBalance = () => {
  const [isLoading, setIsloading] = useState(false);
  const [isError, setIsError] = useState(false);

  /** @function balanceRecovery - This function is responsible for recovering the balance of an employee */
  const createRecoveryEmployeeBalance = useCallback(
    async (customerId: number, accountsInfo: AccountsInfoRequest[]) => {
      {
        setIsloading(true);
        setIsError(false);

        try {
          const { data } = await apiClient.post('/client/balance-recovery-json', {
            customerId: Number(customerId),
            accountsInfo,
          });
          setIsloading(false);
          return data;
        } catch (error) {
          setIsError(true);
          setIsloading(false);
          throw error;
        }
      }
    },
    []
  );

  /** @function balanceRecoveryTotal - This function is responsible for recovering the balance of an employee */
  const createRecoveryEmployeeBalanceTotal = useCallback(
    async ({ customerId, ...filters }: FiltersRequest) => {
      {
        setIsloading(true);
        setIsError(false);

        try {
          const { data } = await apiClient.post('/client/balance-recovery/total', {
            ...filters,
            customerId: Number(customerId),
          });
          setIsloading(false);
          return data;
        } catch (error) {
          setIsError(true);
          setIsloading(false);
          throw error;
        }
      }
    },
    []
  );

  /** @function confirmRecoveryEmployeeBalance - This function is responsible for confirming the balance recovery */
  const confirmRecoveryEmployeeBalance = useCallback(
    async (recoveryId: string, confirm: boolean) => {
      {
        setIsloading(true);
        setIsError(false);

        try {
          const { data } = await apiClient.put('/client/confirm-recovery', {
            recoveryId,
            confirm,
          });
          setIsloading(false);
          return data;
        } catch (error) {
          setIsError(true);
          setIsloading(false);
          throw error;
        }
      }
    },
    []
  );

  return {
    isLoading,
    isError,
    createRecoveryEmployeeBalance,
    createRecoveryEmployeeBalanceTotal,
    confirmRecoveryEmployeeBalance,
  };
};

export default useRecoveryEmployeeBalance;
