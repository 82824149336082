import useSWR from 'swr';

/**
 * Obtains a list of categories by company
 */
const useCategoriesByCustomer = ({ allowRequest = true, customerId }) => {
  const { data, isLoading, error } = useSWR(
    () => (allowRequest && customerId ? `/v2/partner/categories/${customerId}` : false),
    {
      revalidateOnFocus: false,
    }
  );
  return { data: data || [], isLoading, error };
};
export default useCategoriesByCustomer;
