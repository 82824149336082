import useSWR from 'swr';

/**
 * Custom hook for fetching reports links for a specific segment
 * @param {string} customerId - The ID of the customer for which to fetch reports.
 * @returns {Object} - The response object from the SWR fetch.
 */
const useCompanyReports = (customerId) => {
  const props = useSWR(`/v2/partner/reportsV2/${customerId}`);
  return props;
};

export default useCompanyReports;
