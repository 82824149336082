import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isAxiosError } from 'axios';
import { apiClient } from 'config/api';

export interface ExportAllUserAccountsFilters {
  customerId?: number;
  lastName?: string;
  firstName?: string;
  email?: string;
  cuit?: string;
  walletId?: string;
  product?: string;
  categories?: string[];
}

/** Custom hook to manage export all user accounts filtered to xlsx file  */
const useExportAllUserAccountsFiltered = () => {
  const { t } = useTranslation();
  const [isLoading, setIsloading] = useState(false);
  const [error, setError] = useState<string>();

  const exportAllUserAccountsFiltered = useCallback(
    async (filters: ExportAllUserAccountsFilters) => {
      setIsloading(true);
      setError(undefined);
      try {
        const { headers, data } = await apiClient.get('/v2/export/users-accounts', {
          params: filters,
          responseType: 'blob',
        });

        const contentDisposition = headers['Content-Disposition'];
        const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);
        const filename = filenameMatch ? filenameMatch[1] : `${t('COMMON:FILE')}.xlsx`;

        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      } catch (error) {
        if (isAxiosError(error)) {
          setError(error.message);
        }
      } finally {
        setIsloading(false);
      }
    },
    [t]
  );

  return { isLoading, exportAllUserAccountsFiltered, error };
};

export default useExportAllUserAccountsFiltered;
