import { EVENT_CLICK, FIREBASE, SCREENNAME_PROSPECT_CREATION } from '@globalConstants';
import { trackings } from '@utils';

export const EVENT_PROSPECT_CREATION_ADD_ACCOUNT = 'prospectCreation_addAccount';
export const EVENT_PROSPECT_CREATION_ADD_NEW_ACCOUNT = 'prospectCreation_addNewAccount';
export const EVENT_PROSPECT_CREATION_DELETE_ACCOUNT = 'prospectCreation_deleteAccount';
export const EVENT_PROSPECT_CREATION_SEND_ACCOUNT = 'prospectCreation_sendAccount';
export const EVENT_PROSPECT_CREATION_ACCEPT_ACCOUNT = 'prospectCreation_acceptAccount';
export const EVENT_PROSPECT_CREATION_CANCEL_ACCOUNT = 'prospectCreation_cancelAccount';

/**
 * Event object to send to trackings
 * @param element element clicked
 * @returns an array with the event
 */
export const PROSPECT_CREATION_CLICK_EVENT = (element: string) => [
  {
    type: FIREBASE,
    name: EVENT_CLICK,
    props: {
      screen_name: SCREENNAME_PROSPECT_CREATION,
      element: element,
    },
  },
];
/**
 * Function to call trackings with prospect creation click event
 * @param element element clicked
 */
export const prospectCreationClick = (element: string) => {
  trackings(PROSPECT_CREATION_CLICK_EVENT(element));
};
