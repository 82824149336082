import useSWR from 'swr';
import useCustomerId from '../useCustomerId';

/**
 * Gets the details of the company (razón social) selected by the user
 */
const useSelectedCompanyDetails = () => {
  const { customerId } = useCustomerId();

  /**
   * Generates the swr key for the request, or cancels it if the user has no company selected
   * @returns The key string or false
   */
  const key = () => (customerId ? `/v2/partner/customer/${customerId}` : false);

  const props = useSWR(key);

  return props;
};

export default useSelectedCompanyDetails;
