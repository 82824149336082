import { CardProvider } from 'helpers/hooks/useCustomerAssignableProducts';
import { Yup } from '@validations';

export const registerCompanyInPrismaSchema = Yup.object({
  companyType: Yup.string().required(),
  contactEmail: Yup.string().email().required(),
  fantasyName: Yup.string().required(),
});

export const customerProductSchema = Yup.object({
  id: Yup.string().required(),
  provider: Yup.mixed<CardProvider>().oneOf(['GP', 'PRISMA']).required(),
  name: Yup.string().required(),
  displayName: Yup.string().required(),
  cardType: Yup.string().required(),
});

const conditionalColorSchemaValidation = Yup.string().when(
  ['$customizeProduct', '$allowCustomization'],
  {
    is: (customizeProduct: boolean, allowCustomization: boolean) =>
      customizeProduct && allowCustomization,
    then: Yup.string()
      .required('COMMON:VALIDATIONS:REQUIRED_FIELD')
      .matches(/^#?([a-fA-F0-9]{6})$/, 'CLIENTS:ERRORS:INVALID_COLOR_HEX'),
    otherwise: Yup.string().notRequired(),
  }
);

export const addCustomerProductSchema = Yup.object({
  customerId: Yup.number().required(),
  customerProducts: Yup.array(customerProductSchema).min(1).required(),
  registerCompanyInPrisma: registerCompanyInPrismaSchema.nullable(),
  colors: Yup.object({
    main: conditionalColorSchemaValidation,
    light: conditionalColorSchemaValidation,
    dark: conditionalColorSchemaValidation,
    contrast: conditionalColorSchemaValidation,
  }),
  productProviderId: Yup.string().when(['$customizeProduct', '$allowCustomization'], {
    is: (customizeProduct: boolean, allowCustomization: boolean) =>
      customizeProduct && allowCustomization,
    then: Yup.string().required('COMMON:VALIDATIONS:REQUIRED_FIELD'),
    otherwise: Yup.string().notRequired(),
  }),
});

export type AddCustomerProductSchema = Yup.InferType<typeof addCustomerProductSchema>;
export type RegisterCompanyInPrismaSchema = Yup.InferType<typeof registerCompanyInPrismaSchema>;
