import { useCallback, useState } from 'react';
import { isAxiosError } from 'axios';
import { apiClient } from 'config/api';
import { FastDispersionFilters } from './useFastDispersion';

/** Custom hook to manage export all accounts filtered to xlsx file  */
const useExportAllAccountsFiltered = () => {
  const [isLoading, setIsloading] = useState(false);
  const [error, setError] = useState<string>();

  const exportAllAccountsFiltered = useCallback(
    async (customerId: number, filters: FastDispersionFilters) => {
      setIsloading(true);
      setError(undefined);
      try {
        const response = await apiClient.get('/v2/export/fast-dispersion-search', {
          params: { customerId, ...filters },
          responseType: 'blob',
        });

        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'dispersion.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      } catch (error) {
        if (isAxiosError(error)) {
          setError(error.message);
        }
      } finally {
        setIsloading(false);
      }
    },
    []
  );

  return { isLoading, exportAllAccountsFiltered, error };
};

export default useExportAllAccountsFiltered;
