import { Autocomplete } from '@atoms';
import { useURLparams, usePartnerCustomers } from '@hooks';

interface CompanyOption {
  id: number;
  legalName: string;
}

interface SelectCustomerProps {
  disabled?: boolean;
  placeholder?: string;
}

/**
 * This component renders a customer select Autocomplete using the Autocomplete component.
 * @component SelectCustomer
 */
const SelectCustomer: React.FC<SelectCustomerProps> = ({
  disabled = false,
  placeholder = 'CLIENTS:SELECT_COMPANY',
}: SelectCustomerProps): JSX.Element => {
  const { data, isLoading } = usePartnerCustomers();
  const { params, setURLparams } = useURLparams();
  const value = 'customerId' in params && params?.customerId ? String(params.customerId) : '';

  /**
   * Handles the change event of the Autocomplete component
   * @function handleChange
   */
  const handleChange = (_event: React.SyntheticEvent, selectedOption: CompanyOption | null) => {
    if (selectedOption) {
      setURLparams({ customerId: String(selectedOption.id) });
    }
  };

  /**
   * Handles the label to be displayed in the Autocomplete component
   * @function getOptionLabel
   */
  const getOptionLabel = (value: { label: string }): string =>
    typeof value === 'object'
      ? value.label ?? ''
      : data?.find(({ id }) => id === Number(value))?.legalName || '';

  return (
    <Autocomplete
      disabled={disabled}
      isLoading={isLoading}
      onChange={handleChange}
      value={value}
      items={data || []}
      placeholder={placeholder}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={({ id }: { id: string }, value: string) => id === value}
      parseOption={({ id, legalName }: CompanyOption) => ({ id, label: legalName })}
    />
  );
};

export default SelectCustomer;
