import { EVENT_CLICK, FIREBASE, SCREENNAME_EMPLOYEE_DETAILS } from '@globalConstants';
import { trackings } from '@utils';

export const EVENT_EMPLOYEE_DETAILS_GO_BACK = 'employeeDetails_goBack';
export const EVENT_EMPLOYEE_DETAILS_EDIT_CATEGORY = 'employeeDetails_editCategory';
export const EVENT_EMPLOYEE_DETAILS_CONFIRM_CATEGORY = 'employeeDetails_confirmCategory';
export const EVENT_EMPLOYEE_DETAILS_CANCEL_CATEGORY = 'employeeDetails_cancelCategory';

export const EVENT_EMPLOYEE_DETAILS_RECOVER_BALANCE = 'employeeDetails_recoverBalance';
export const EVENT_EMPLOYEE_DETAILS_CONFIRM_RECOVER_BALANCE =
  'employeeDetails_confirmRecoverBalance';
export const EVENT_EMPLOYEE_DETAILS_CANCEL_RECOVER_BALANCE = 'employeeDetails_cancelRecoverBalance';

export const EVENT_EMPLOYEE_DETAILS_USER_DETAIL = 'employeeDetails_viewUserDetail';
export const EVENT_EMPLOYEE_DETAILS_SELECT_PRODUCT = 'employeeDetails_selectProduct';

export const EVENT_EMPLOYEE_DETAILS_FILTER_MOVEMENTS = 'employeeDetails_filterMovements';
export const EVENT_EMPLOYEE_DETAILS_CLEAR_FILTER_MOVEMENTS = 'employeeDetails_clearFilterMovements';
export const EVENT_EMPLOYEE_DETAILS_EXPORT_MOVEMENTS = 'employeeDetails_exportMovements';

export const EVENT_EMPLOYEE_DETAILS_ALLOW_DISPERSION = 'employeeDetails_allowDispersion';

/**
 * Event object to send to trackings
 * @param element element clicked
 * @returns an array with the event
 */
export const EMPLOYEE_DETAILS_CLICK_EVENT = (element: string) => [
  {
    type: FIREBASE,
    name: EVENT_CLICK,
    props: {
      screen_name: SCREENNAME_EMPLOYEE_DETAILS,
      element: element,
    },
  },
];
/**
 * Function to call trackings with prospect creation click event
 * @param element element clicked
 */
export const employeeDetailsClick = (element: string) => {
  trackings(EMPLOYEE_DETAILS_CLICK_EVENT(element));
};

interface FilterMovements {
  fromDate?: string;
  toDate?: string;
  transactionStatus?: string;
  transactionType?: string;
}
/**
 * Event triggered when user filters an account movement
 * @param filters filters values
 */
export const employeeDetailsFilterMovements = (filters: FilterMovements) => {
  trackings([
    {
      type: FIREBASE,
      name: EVENT_CLICK,
      props: {
        screen_name: SCREENNAME_EMPLOYEE_DETAILS,
        element: EVENT_EMPLOYEE_DETAILS_FILTER_MOVEMENTS,
        ...filters,
      },
    },
  ]);
};
interface RecoverBalance {
  amount: number;
  fullRecovery: boolean;
  userAccountNumber: string;
}
/**
 * Event triggered when user recovers balance
 * @param body recover balance value
 */
export const employeeDetailsRecoverBalance = (values: RecoverBalance) => {
  trackings([
    {
      type: FIREBASE,
      name: EVENT_CLICK,
      props: {
        screen_name: SCREENNAME_EMPLOYEE_DETAILS,
        element: EVENT_EMPLOYEE_DETAILS_CONFIRM_RECOVER_BALANCE,
        ...values,
      },
    },
  ]);
};
interface AllowDispersion {
  id: string;
  userId: string;
  customerProductId: string;
  accountId: string;
  allowDispersion: boolean;
  customer_product_id: string;
}
/**
 * Event triggered when user allow dispersion
 * @param body
 */
export const employeeDetailsAllowDisperion = (values: AllowDispersion) => {
  trackings([
    {
      type: FIREBASE,
      name: EVENT_CLICK,
      props: {
        screen_name: SCREENNAME_EMPLOYEE_DETAILS,
        element: EVENT_EMPLOYEE_DETAILS_ALLOW_DISPERSION,
        ...values,
      },
    },
  ]);
};
