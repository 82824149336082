import useSWR from 'swr';

interface UseProductsByCustomerParams {
  allowRequest?: boolean;
  customerId: number;
}
/**
 * Obtains a list of products by customer_id
 */
const useProductsByCustomer = ({
  allowRequest = true,
  customerId,
}: UseProductsByCustomerParams) => {
  const { data, isLoading, error } = useSWR(
    () => (allowRequest && customerId ? `/partner/products/customer/${customerId}` : false),
    {
      revalidateOnFocus: false,
    }
  );

  return { data, isLoading, error };
};

export default useProductsByCustomer;
