import { useMemo } from 'react';
import { useCompanyStore, useCustomerStore } from '@stores';
import useURLparams from './useURLparams';
import useUser from './useUser';

interface useCustomerIdReturn {
  customerId: number;
}

/**
 * @hook useCustomerId
 * @description This hook is used to get the customerId based on the user role and the selected company.
 */
const useCustomerId = (): useCustomerIdReturn => {
  const { user } = useUser();
  const { selectedCompany } = useCompanyStore();
  const { params } = useURLparams();
  const customerIdParams = (params as { customerId?: string }).customerId;
  const isInternalUser = user?.isInternal;
  const selectedCustomer = useCustomerStore((state) => state.selectedCustomer);

  const customerId = useMemo(() => {
    const hasCustomerIdParams = Boolean(customerIdParams);

    if (isInternalUser) {
      return hasCustomerIdParams ? customerIdParams : selectedCustomer;
    }

    return selectedCompany;
  }, [customerIdParams, isInternalUser, selectedCompany, selectedCustomer]);

  return { customerId };
};

export default useCustomerId;
