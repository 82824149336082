import { useCategories, useSegments, useSelectedCustomerProducts } from '@hooks';
import { VALIDATE_OBJECT, VALIDATE_REQUIRED_STRING, VALIDATE_ARRAY_OF_STRINGS } from '@validations';

export const FIELD_NAMES = {
  PRODUCT: 'product',
  CATEGORY: 'category',
  SEGMENT: 'segment',
};

export const ASSIGN_PRODUCT_FIELDS_CONFIG = [
  {
    name: FIELD_NAMES.PRODUCT,
    label: 'PRODUCT_LABEL',
    id: 0,
    multiple: false,
    getOptions: useSelectedCustomerProducts,
  },
  {
    name: FIELD_NAMES.CATEGORY,
    label: 'CATEGORY_LABEL',
    id: 1,
    multiple: true,
    showCheckbox: true,
    getOptions: useCategories,
    customHandleChange: ({ e, inputName, handleChange }) => {
      const selectedOptions = e.target.value;
      const lastOption = selectedOptions[selectedOptions.length - 1];
      if (lastOption !== 'GENERIC' && selectedOptions.includes('GENERIC')) {
        e.target.value = selectedOptions.filter((option) => option !== 'GENERIC');
      } else if (lastOption === 'GENERIC' && selectedOptions.includes('GENERIC')) {
        e.target.value = ['GENERIC'];
      }
      handleChange(e, inputName);
    },
  },
  {
    name: FIELD_NAMES.SEGMENT,
    label: 'SEGMENT_LABEL',
    id: 2,
    multiple: false,
    getOptions: useSegments,
  },
];

export const validationSchema = VALIDATE_OBJECT({
  product: VALIDATE_REQUIRED_STRING,
  category: VALIDATE_ARRAY_OF_STRINGS.required('ERRORS:FIELD_REQUIRED'),
});

export const ALERT_TYPES = {
  DIFFERENT_CUSTOMERS: 'DIFFERENT_CUSTOMERS',
  USERS_FAILED: 'USERS_FAILED',
  SUCCESS: 'SUCCESS',
};

/**
 * Gets alert info depending on the BE's response
 * @param {*} assignProductData
 * @param {*} type
 */
export const getAlertInfo = (assignProductData, t) => {
  const alerts = {
    SUCCESS: {
      title: t('EMPLOYEES:ASSIGN_PRODUCT:CONFIRM_MODAL_TITLE'),
      description: t('EMPLOYEES:ASSIGN_PRODUCT:CONFIRM_MODAL_DESCRIPTION', {
        product: assignProductData.product,
        categories: assignProductData.categories,
      }),
      closeButtonText: t('COMMON:ACCEPT'),
      variant: 'success',
      modalVisible: assignProductData.users_failed?.length === 0,
    },
    DIFFERENT_CUSTOMERS: {
      description: t('EMPLOYEES:ASSIGN_PRODUCT:DIFFERENT_CUSTOMERS_ERROR_DESCRIPTION'),
      title: t('EMPLOYEES:ASSIGN_PRODUCT:DIFFERENT_CUSTOMERS_ERROR_TITLE'),
      modalVisible: assignProductData.error_different_customers,
    },
    USERS_FAILED: {
      description: t('EMPLOYEES:ASSIGN_PRODUCT:ERROR_DESCRIPTION', {
        product: assignProductData.product,
      }),
      title: t('EMPLOYEES:ASSIGN_PRODUCT:ERROR_TITLE'),
      showErrorList: true,
      modalVisible: assignProductData.users_failed?.length > 0,
      errors: assignProductData.users_failed,
    },
  };
  return alerts[assignProductData.alertType];
};
