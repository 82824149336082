import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { defaultProductColor } from 'theme/colors';
import { palette } from 'theme/palette';
import { Alert, Box, CircularProgress, Grid, Stack, useMediaQuery } from '@mui/material';
import {
  IconCreditCard,
  IconUserCircle,
  IconMail,
  IconCirclePlus,
  IconBuildingStore,
} from '@tabler/icons-react';
import { ConfirmModal, Snackbar, StrongModalText } from '@atoms';
import { PERMISSIONS, PRODUCTS_COLORS } from '@globalConstants';
import { useEmployeeDetails, useUser, useURLparams, usePermissions } from '@hooks';
import { FullWidthContent, MainMenuLayout } from '@layouts';
import { GoBackButton, CardHeader, ProductTabSelector } from '@molecules';
import { useClientStore } from '@stores';
import { getParsedCategories } from '@utils';
import { apiClient } from '@config/api';
import { RESENDMAIL_EDITOR_ROLES } from './constants';
import {
  employeeDetailsClick,
  EVENT_EMPLOYEE_DETAILS_GO_BACK,
  EVENT_EMPLOYEE_DETAILS_USER_DETAIL,
} from './events';
import styles from './styles';
import { DetailsTab, ProductsTab, MovementsTab } from './tabs';
import { REGISTER_STATUS, ITEMS } from './tabs/detailsTab/constants';
import { useProductsBy } from './tabs/productsTab/useProducts';
import AssignNewProduct from '../employeesList/assignNewProduct';

/**
 * Client detail screen
 */
const EmployeeDetails = () => {
  const { username } = useParams();
  const {
    data: client,
    isLoading,
    mutate,
    setAssignProductOpen,
    assignProductOpen,
  } = useEmployeeDetails(username);
  const { state } = useLocation();
  const {
    data: products,
    mutate: productsMutate,
    isLoading: isProductsLoading,
    error: productsError,
    selectedProduct,
    setSelectedProduct,
  } = useProductsBy(client?.id, state?.account_number);
  const { user } = useUser();
  const { clientSelected, searchParams } = useClientStore((state) => ({
    clientSelected: state.clientSelected,
    searchParams: state.searchParams,
  }));
  const [showSendMailModal, setShowSendMailModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [editSuccess, setEditSuccess] = useState(false);
  const [failMessage, setFailMessage] = useState(null);
  const [editFailed, setEditFailed] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { params, setURLparams } = useURLparams();
  const canViewAddProductButton = usePermissions([PERMISSIONS.assignUserProducts]);
  const canViewAllMovements = usePermissions([PERMISSIONS.viewProductBenefitBalance]);
  const canResendEmail = user.roles.some((role) => RESENDMAIL_EDITOR_ROLES.includes(role));
  const showResendMailButton =
    canResendEmail && client?.registrationStatus === REGISTER_STATUS.NOT_REGISTERED;
  const responsive = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const selectedCardHeaderMenuOptions = params?.tab;
  const showEditButton =
    ITEMS.some((field) => field.editorRoles?.some((role) => user.roles.includes(role))) &&
    (client?.registrationStatus === REGISTER_STATUS.REGISTERED ||
      client?.registrationStatus === REGISTER_STATUS.NOT_REGISTERED);

  /**
   * Check if a user us allowed to enter a tab
   * @param {object} tab - The tab object
   * @returns {boolean}
   */

  /**
   * Goes back to client search with the selected client param
   */
  function handleGoBack() {
    employeeDetailsClick(EVENT_EMPLOYEE_DETAILS_GO_BACK);
    if (selectedCardHeaderMenuOptions) {
      navigate(`/detalle-cliente/${client.username}`);
    } else {
      navigate(`/colaboradores?${new URLSearchParams(searchParams).toString()}`);
    }
  }

  /**
   * add icon, title and onclick function to each product
   */
  const getParsedProducts = () => {
    const items = products?.map((item) => {
      const productColor = item.colors || PRODUCTS_COLORS[item.product] || defaultProductColor;

      return {
        ...item,
        icon: <IconCreditCard color={productColor.main} />,
        title: getParsedCategories(item.categories),
        onClick: () => {
          setURLparams({});
          setSelectedProduct(item);
        },
      };
    });
    return items;
  };

  /**
   * Re-send register email
   */
  async function resendEmail() {
    try {
      await apiClient.post(`/client/prospects/send-notification/${client.walletId}`);
      setSuccessMessage('EMPLOYEES:DETAIL:SUCCESS_RESEND_EMAIL');
      setEditSuccess(true);
    } catch (error) {
      setFailMessage(t('EMPLOYEES:DETAIL:ERRORS:FAIL_RESEND_EMAIL'));
      setEditFailed(true);
    } finally {
      setShowSendMailModal(false);
    }
  }

  const THREE_DOTS_MENU_OPTIONS = [
    {
      text: t('EMPLOYEES:DETAIL:EDIT'),
      canViewOption: showEditButton,
      onClick: () => {
        setURLparams({ tab: 'editar' });
        setEditUser(true);
      },
      name: 'editar',
      getContent: () => (
        <DetailsTab
          client={client}
          isEditing={editUser}
          setEditing={setEditUser}
          handleGoBack={() => {
            setEditUser(false);
            navigate(-1);
          }}
          mutate={mutate}
        />
      ),
    },
    {
      text: t('EMPLOYEES:DETAIL:VIEW_DETAIL'),
      canViewOption: true,
      onClick: () => {
        employeeDetailsClick(EVENT_EMPLOYEE_DETAILS_USER_DETAIL);
        setURLparams({ tab: 'detalle' });
      },
      name: 'detalle',
      getContent: () => <DetailsTab client={client} mutate={mutate} />,
    },
    {
      text: t('EMPLOYEES:DETAIL:VIEW_ALL_MOVEMENTS'),
      canViewOption: canViewAllMovements,
      onClick: () => {
        setURLparams({ tab: 'movimientos' });
      },
      name: 'movimientos',
      getContent: () => (
        <MovementsTab client={client} showAllMovements={true} showGoBackButton={true} />
      ),
    },
    {
      text: t('EMPLOYEES:DETAIL:RESEND_EMAIL_BUTTON'),
      canViewOption: showResendMailButton,
      onClick: () => setShowSendMailModal(true),
    },
  ];

  /**
   * get the selected item from the cardheader options menu
   */
  const getSelectedOptionContent = () => {
    const result = THREE_DOTS_MENU_OPTIONS.find(
      (item) => item.name && item.name === selectedCardHeaderMenuOptions
    );
    return result;
  };

  const selectedOptionContent = getSelectedOptionContent();

  return (
    <MainMenuLayout>
      <FullWidthContent>
        {isLoading || isProductsLoading ? (
          <Box sx={styles.spinnerContainer}>
            <CircularProgress size={60} />
          </Box>
        ) : client ? (
          <Stack gap={3}>
            <CardHeader
              title={`${client.firstName} ${client.lastName}`}
              chipLabel={t(
                client?.registrationStatus
                  ? `EMPLOYEES:DETAIL:STATUS:${client.registrationStatus}`
                  : 'EMPLOYEES:DETAIL:STATUS:UNDEFINED'
              )}
              subtitleOptions={[
                {
                  icon: <IconMail color={palette.primary.main} />,
                  text: client.email,
                },
                user.isInternal && {
                  icon: <IconBuildingStore color={palette.primary.main} />,
                  text: client.customerLegalName,
                },
              ]}
              leftComponentOptions={{
                type: 'icon',
                icon: <IconUserCircle strokeWidth={1} />,
                customStyle: { icon: styles.iconAvatar },
              }}
              rightComponentOptions={
                !params.tab && {
                  type: 'threeDotsMenu',
                  menuOptions: THREE_DOTS_MENU_OPTIONS,
                }
              }
            />

            {selectedCardHeaderMenuOptions ? (
              selectedOptionContent.getContent()
            ) : (
              <>
                {productsError ? (
                  <Alert variant='filled' severity='error'>
                    {t('EMPLOYEES:DETAIL:ERRORS:PRODUCTS_FETCH_GENERIC_ERROR')}
                  </Alert>
                ) : (
                  selectedProduct && (
                    <>
                      <ProductTabSelector
                        selectedTab={selectedProduct}
                        items={getParsedProducts()}
                        keyProp='account_number'
                        titleProp='title'
                        rightButton={
                          canViewAddProductButton && {
                            icon: <IconCirclePlus />,
                            text: 'Agregar cuenta',
                            onClick: () => {
                              setAssignProductOpen(true);
                            },
                          }
                        }
                      />
                      <Box sx={styles.containerProductMovements(responsive)}>
                        <ProductsTab
                          client={client}
                          product={selectedProduct}
                          isProductsLoading={isProductsLoading}
                          productsMutate={productsMutate}
                        />
                        <MovementsTab
                          client={client}
                          accountNumber={selectedProduct?.account_number}
                          accountId={selectedProduct?.account_id}
                        />
                      </Box>
                    </>
                  )
                )}
              </>
            )}
            {(clientSelected || selectedCardHeaderMenuOptions) && (
              <GoBackButton backFunction={handleGoBack} variant='outlined' />
            )}
          </Stack>
        ) : (
          <Grid container>
            <Alert variant='filled' severity='error'>
              {t('EMPLOYEES:DETAIL:ERRORS:CLIENT_FETCH_GENERIC_ERROR')}
            </Alert>
          </Grid>
        )}
        <ConfirmModal
          modalVisible={showSendMailModal}
          setModalVisible={setShowSendMailModal}
          description={
            <Trans
              i18nKey='EMPLOYEES:DETAIL:RESEND_EMAIL_DESCRIPTION'
              values={{ email: client?.email }}
            >
              Se va a reenviar un email a
              <u>
                <StrongModalText>{client?.email}</StrongModalText>
              </u>
            </Trans>
          }
          title={t('EMPLOYEES:DETAIL:RESEND_EMAIL')}
          handleSubmit={resendEmail}
        />
        <AssignNewProduct
          assignProductOpen={assignProductOpen}
          setAssignProductOpen={setAssignProductOpen}
          selectedUsers={[client]}
        />
        <Snackbar
          open={editFailed}
          message={t(failMessage)}
          severity={'error'}
          handleClose={() => setEditFailed(false)}
        />
        <Snackbar
          open={editSuccess}
          message={t(successMessage)}
          severity='success'
          handleClose={() => setEditSuccess(false)}
        />
      </FullWidthContent>
    </MainMenuLayout>
  );
};

export default EmployeeDetails;
